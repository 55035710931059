/*
* Author: Iyad Al-Kassab
* Date: 07/04/2024
* Description: Footer component
*/

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import SocialMedia from '../SocialMedia/SocialMedia.component';

import './Footer.style.css';

import logo from '../../img/wagerly-logo-white.png';

const Footer = () => {

  return (
    <>
      <footer className="footer">
        <div className="page-section col-md-4 footer-section footer-warnings-section nopadding-mobile">
          <div className="app-logo">
            <img src={logo} alt="wagerly-logo" />
          </div>
          <br></br>
          <div className="footer-slogan footer-first-section-text">Your #1 Sports Betting Reference</div>
          <br></br>
          <div className="footer-first-section-text">This website does not offer gambling. It provides information about betting odds and sports.</div>
          <br></br>
          <div className="footer-first-section-text">Please gamble responsibly. For help and support, visit <a className="reponsible-gambling" href="https://www.responsiblegambling.org/">Responsible Gambling</a>.</div>
          <br></br>
          <div className="footer-first-section-text">This site is 100% for entertainment purposes only and does not involve real money betting. If you or someone you know has a gamblinb problem and wants help, call 1-800 GAMBLER. This service is intended for adult users only.</div>
          <br></br>
        </div>

        <div className="footer-section col-6 col-md-auto nopadding-mobile col-lg-auto">
          {/* Second Section - Sport Betting Odds */}
          <h3 className="footer-menu-text">Navigation</h3>
          <div className="sport-odds-links">
            <NavLink to="/" className="footer-sport-links">Home</NavLink>
            <NavLink to="/aistats" className="footer-sport-links">AI Stats</NavLink>
            <NavLink to="/ourpicks" className="footer-sport-links">Our Picks</NavLink>
            <NavLink to="/freepicks" className="footer-sport-links">Free Picks</NavLink>
            <NavLink to="/casinos" className="footer-sport-links">Best Casinos</NavLink>
            <NavLink to="/affiliate" className="footer-sport-links">Affiliate</NavLink>
            <NavLink to="/pricing" className="footer-sport-links">Pricing</NavLink>
          </div>
        </div>

        <div className="footer-section col-6 col-md-auto nopadding-mobile col-lg-4 footer-fix">
          <div className="col-12 col-md-auto col-lg-auto">
            <h3 className="footer-menu-text">Contact Us</h3>
            <div className="support-links">
              <a href="mailto:support@wagerly.com">support@wagerly.com</a>
            </div>
          </div>
          <div className="col-12 col-md-auto col-lg-auto">
            <h3 className="footer-menu-text">Follow Us</h3>
            <SocialMedia />
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="footer-bottom-text">
          <NavLink to="/privacy" className="link-bottom-footer">Privacy Policy</NavLink>
          <NavLink to="/tos" className="link-bottom-footer">Terms Of Service</NavLink>
          <span>&copy; 2024 Wagerly.com. All Rights Reserved</span>
        </div>
      </div>
    </>
  )
}

export default Footer;