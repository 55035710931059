import React, { useEffect, useState } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import { AnimatePresence } from "framer-motion";

import { getUser } from "./slices/auth.slice";

import './App.css';

import Home from './pages/Home/Home.page';
import Login from './pages/Login/Login.page';
import Signup from './pages/Signup/Signup.page';
import Email from './pages/Email/Email.page';
import AiStats from './pages/AiStats/AiStats.page';
import Picks from './pages/Picks/Picks.page';
import Casinos from './pages/Casinos/Casinos.page';
import Affiliate from './pages/Affiliate/Affiliate.page';
import FreeAccess from './pages/FreeAccess/FreeAccess.page';
import Pricing from './pages/Pricing/Pricing.page';
import TOS from './pages/TOS/TOS.page';
import Settings from './pages/Settings/Settings.page';
import AdminPage from './pages/Admin/Admin.page';
import Privacy from './pages/Privacy/Privacy.page';
import Verify from './pages/Verify/Verify.page';

function App() {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const location = useLocation();
  const [user, setUser] = React.useState(null); {/* we need the actual user */ }
  const dispatch = useAppDispatch();
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  useEffect(() => {

    if (basicUserInfo) {

      const checkVerification = async () => {
        if (basicUserInfo.id != undefined) {
          try {
            const user = await dispatch(getUser(basicUserInfo.id));
            setIsAdmin(user.payload['isAdmin'] as boolean ?? false)
          } catch (error) {
            setIsAdmin(false);
          }
        } else {
          setIsAdmin(false);
        }
      };

      checkVerification();
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [location.pathname]);

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>

          {/* USERS ROUTES */}

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/email-confirmation" element={<Email />} />
          <Route path="/aistats" element={<AiStats />} />
          <Route path="/ourpicks" element={<Picks />} />
          <Route path="/freeaccess" element={<FreeAccess />} />
          <Route path="/casinos" element={<Casinos />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/settings/:option" element={<Settings />} />
          <Route path="/verify" element={<Verify />} />

          {/* ADMIN ROUTES */}
          <Route path='/d0932rfmi0dqlw1kd0j8/admin' element={<AdminPage />}
          />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;


