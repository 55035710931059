import React, { useEffect, useState } from 'react';

import settingsIcon from '../../img/profile-icons/setting.png';
import bankCardInformationIcon from '../../img/profile-icons/bank-card-information.png';
import subscriptionIcon from '../../img/profile-icons/subscription.png';
import logoutIcon from '../../img/profile-icons/logout.png';

import './profileButton.style.css';

interface ProfileButtonProps {
  email: string;
  onSettingsClick: () => void;
  onBillingClick: () => void;
  onSubscriptionClick: () => void;
  onLogoutClick: () => void;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ email, onSettingsClick, onBillingClick, onSubscriptionClick, onLogoutClick }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const handleProfileClick = () => {
    setShowDropdown(!showDropdown);
  };

  const username = email.split('@')[0];

  return (
    <div className="profile-button-container">
      <button onClick={handleProfileClick} className="profile-button">
        <div className="profile-initial">
          {username.charAt(0).toUpperCase()}
        </div>
      </button>
      {showDropdown && (
        <>
          <div className="background-close-dropdown" onClick={() => setShowDropdown(false)}></div>
          <div className="dropdown-menu">
            <div className="dropdown-header">
              <div className="profile-initial-dropdown">
                {username.charAt(0).toUpperCase()}
              </div>
              <div className='profile-inner-container'>
                <div id="username-dropdown">{username}</div>
                <div id="email-dropdown">{email}</div>
              </div>
            </div>
            <button onClick={onSettingsClick} className="dropdown-item">
              <span className="icon-dropdown"><img src={settingsIcon} alt='settings' /></span> Settings
            </button>
            <button onClick={onBillingClick} className="dropdown-item">
              <span className="icon-dropdown"><img src={bankCardInformationIcon} alt='info' /></span> Billing info
            </button>
            <button onClick={onSubscriptionClick} className="dropdown-item">
              <span className="icon-dropdown"><img src={subscriptionIcon} alt='sub' /></span> Subscription
            </button>
            <button onClick={onLogoutClick} className="dropdown-item logout-dropdown-item">
              <span className="icon-dropdown"><img src={logoutIcon} alt='logout' /></span> Log out
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileButton;