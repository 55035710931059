// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-media {
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.social-media-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-top: 5px;
  filter: brightness(0) invert(1);
}`, "",{"version":3,"sources":["webpack://./src/components/SocialMedia/SocialMedia.style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,+BAA+B;AACjC","sourcesContent":[".social-media {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-right: 0px;\r\n}\r\n\r\n.social-media-image {\r\n  width: 30px;\r\n  height: 30px;\r\n  margin-right: 5px;\r\n  margin-top: 5px;\r\n  filter: brightness(0) invert(1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
