import { useEffect, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';
import Modal from '../../components/Modal/Modal.component';
import { motion } from 'framer-motion';
import './Settings.style.css';

import { Prices } from '../../classes/Prices';

import loadingGIF from '../../img/loading.gif';
import checkboxIcon from '../../img/checkbox.png';
import closeboxIcon from '../../img/close.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { cancelSubscription } from "../../slices/sub.slice";
import { checkVerification } from "../../slices/auth.slice";

import userlock from '../../img/user-lock.svg';

interface ISubscription {
  status: string;
  orderId: number;
  dateCreated: string;
  customerId: number;
  subscription_price: string;
  biliing_period: string;
  product_name: string;
  productId: number;
}

const Settings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { option } = useParams<{ option: string }>();
  const [selectedOption, setSelectedOption] = useState(option);
  const [subscription, setSubscription] = useState<ISubscription | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [isCancellingSub, setIsCancellingSub] = useState(false);
  const [fufilledRequest, setFufilledRequest] = useState(false);
  const [isEmailVerified, setEmailVerified] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [cancellingStatus, setCancellingStatus] = useState("Cancelling Subscription... Please wait");

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  useEffect(() => {
    if (option === 'details' || option === 'billing' || option === 'subscription') {
      setSelectedOption(option);
    } else {
      setSelectedOption('details');
    }

    const fetchVerification = async () => {
      if (basicUserInfo) {
        try {
          const res = await dispatch(
            checkVerification({
              name: basicUserInfo.first_name,
              email: basicUserInfo.email
            })
          ).unwrap();

          setEmailVerified(res.data.message ?? false)

        } catch (e) {
          console.error(e);
          setEmailVerified(false);
        }
      } else {
        setEmailVerified(false);
      }
    }

    const canAccess = async () => {
      if (basicUserInfo) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/${basicUserInfo.id}`);
          if (!response.ok) throw new Error('Failed to fetch subscription data');
        } catch (err) {
          console.error(err);
        }
      }
      setIsLoading(false); // Stop loading when check is complete
    };

    fetchVerification()
    canAccess();

  }, [basicUserInfo, option]);

  useEffect(() => {
    fetchSubscription();
  }, [selectedOption, basicUserInfo]);

  const fetchSubscription = async () => {
    if (selectedOption === 'subscription' && basicUserInfo) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/${basicUserInfo.id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch subscription data');
        }
        const data = await response.json();
        setSubscription(data.subscription);
      } catch (err) {
        setError('Error fetching subscription data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const getSubscriptionFeatures = (level: string) => {
    return Prices.getDetailsForItem(level);
  };

  const handleCancelSubscription = async () => {

    if (isConfirmChecked && subscription && basicUserInfo?.email && !isCancellingSub) {
      setIsCancellingSub(true);
      toggleCancelModal();
      toggleLoadingModal();

      try {
        const res = await dispatch(
          cancelSubscription({
            subscriptionID: `${subscription.orderId}`,
            status: "inactive",
            email: basicUserInfo.email,
          })
        ).unwrap();

        setFufilledRequest(true);

        if (res) {
          setCancellingStatus(res.data.message);

          window.location.reload();
        } else {
          console.log("error")
          setCancellingStatus("An unexpected error occured. Please try again later");
        }
      } catch (e) {
        console.error(e);
        setCancellingStatus("An unexpected error occured. Please try again later");
      }
    }
  };

  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
    if (!isCancelModalOpen) {
      setIsConfirmChecked(false);
    }
  };

  const toggleLoadingModal = () => {
    if (!isCancellingSub) {
      setIsLoadingModalOpen(!isLoadingModalOpen);
      fetchSubscription();
    }
  };

  const savePersonalInfo = () => {
    // let first_name = document.getElementById('info_first_name');
    // let last_name = document.getElementById('info_last_name');
    // let email = document.getElementById('info_email');
    // let tel = document.getElementById('info_tel');
  }

  const renderSubscriptionContent = () => {
    if (loading) {
      return <p>Loading subscription information...</p>;
    }
    if (error) {
      return <p className="error-message">{error}</p>;
    }
    if (!subscription) {
      return <p>No subscription information available.</p>;
    }

    const subscriptionLevel = subscription.product_name;
    const features = getSubscriptionFeatures(subscriptionLevel);
    const isFree = Prices.isFree(subscriptionLevel);

    return (
      <>
        {isEmailVerified ? (<>
          <h2>Subscription</h2>
          <p>Your current subscription and features</p>
          <p className='warning-message'>If you bought any subscription please allow 15 mins to sync or contact <a href='mailto:info@wagerly.com'>info@wagerly.com</a></p>
          <div className="subscription-plan">
            <h3>Your current plan</h3>
            <div className="plan-details">
              <div className="plan-header">
                <div className="plan-title">{subscriptionLevel}</div>
              </div>
              <div className="under-plan-price"><span>${subscription.subscription_price}</span> <span>
                {subscription.biliing_period ? (<>per {subscription.biliing_period}</>) : (<></>)}</span></div>
              <div className="plan-features">
                <h4>FEATURES</h4>
                <ul>
                  {features.map((feature, index) => (
                    <div className="price-card-feature" key={index}>
                      <span className="price-card-feature-checkbox-container">
                        <img width="20px" src={isFree ? closeboxIcon : checkboxIcon} alt="checkbox" />
                      </span>
                      <span className="feature-text">{feature}</span>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="subscription-buttons">
                <NavLink className="upgrade-button" to={"/pricing"}>See plans</NavLink>
                {subscription.status != 'inactive' ? (
                  <button className="cancel-button" onClick={toggleCancelModal}>Cancel subscription</button>
                ) : (<></>)}
              </div>
            </div>
          </div>
        </>) : (<>
          <div className="no-picks-container aistats">
            <div className="no-picks-row">
              <div className="n-picks-inner">
                <div className='access-denied-symbol'><img src={userlock} /></div>
                <h5>Access is restricted</h5>
                <span id="picks-subtitle">Please verify your email to access this page.</span>
                <NavLink to="/" className="access-denied-btn">Return</NavLink>
              </div>
            </div>
          </div>
        </>)}
      </>
    );
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >
        <MenuBar />

        {isEmailVerified ? (<>
          <div className="page-container">
            <div className="settings-page-container">
              <div className="settings-page">
                <h1>Settings</h1>
                <p>Manage your billing and preferences here.</p>
                <div className="row">
                  <div className="settings-sidebar col-lg-2 col-md-2 col-12">
                    <div className={`settings-option col-4 col-lg-12 col-md-12 ${selectedOption === 'details' ? 'selected' : ''}`} onClick={() => setSelectedOption('details')}>My details</div>
                    <div className={`settings-option col-4 col-lg-12 col-md-12 ${selectedOption === 'billing' ? 'selected' : ''}`} onClick={() => setSelectedOption('billing')}>Billing info</div>
                    <div className={`settings-option col-4 col-lg-12 col-md-12 ${selectedOption === 'subscription' ? 'selected' : ''}`} onClick={() => setSelectedOption('subscription')}>Subscription</div>
                  </div>
                  <div className="settings-content col-lg-10 col-md-10 col-12">
                    {selectedOption === 'details' && (
                      <>
                        <h2>Personal info</h2>
                        <p>Update your personal details here.</p>
                        <form className="settings-form">
                          <div className="form-group">
                            <label className='col-md-5 col-lg-3 nopadding-mobile'>Name</label>
                            <input id='info_first_name' type="text" placeholder="First name" value={basicUserInfo?.first_name} />
                            <input id='info_last_name' type="text" placeholder="Last name" value={basicUserInfo?.last_name} />
                          </div>
                          <div className="form-group">
                            <label className='col-md-5 col-lg-3 nopadding-mobile'>Email address</label>
                            <input id='info_email' type="email" placeholder="iwant@bets.com" value={basicUserInfo?.email} />
                          </div>
                          <div className="form-group">
                            <label className='col-md-5 col-lg-3 nopadding-mobile'>Phone Number</label>
                            <div className="phone-input">
                              <select defaultValue="US">
                                <option value="US">🇺🇸 +1</option>
                              </select>
                              <input id='info_tel' type="tel" placeholder="+1 (555) 000-0000" value={basicUserInfo?.phone} />
                            </div>
                          </div>
                          <div className="save-button-container">
                            <button type="submit" onClick={savePersonalInfo} className="save-button">Save</button>
                          </div>
                        </form>
                      </>
                    )}
                    {selectedOption === 'billing' && (
                      <>
                        <h2>Payment method</h2>
                        <p>Update your billing details and address.</p>
                        <form className="settings-form">
                          <div className="form-group">
                            <label className='col-md-5 nopadding-mobile'>Card details</label>
                            <input type="text" placeholder="Name on card" />
                            <input type="text" placeholder="Expiry" />
                          </div>
                          <div className="form-group delimiter">
                            <label className='col-md-5 nopadding-mobile label-empty'>&nbsp;</label>
                            <input type="text" placeholder="Card number" />
                            <input type="text" placeholder="CVV" />
                          </div>
                          <div className="form-group delimiter">
                            <span className='col-md-5 nopadding-mobile'>
                              <label>Email address</label>
                              <p className="smaller-text-form-group">Invoices will be sent to this email address.</p>
                            </span>
                            <input type="email" placeholder="Email address" />
                          </div>
                          <div className="form-group delimiter">
                            <label className='col-md-5 nopadding-mobile'>Street address</label>
                            <input type="text" placeholder="Street address" />
                          </div>
                          <div className="form-group delimiter">
                            <label className='col-md-5 nopadding-mobile'>City</label>
                            <input type="text" placeholder="City" />
                          </div>
                          <div className="form-group delimiter">
                            <label className='col-md-5 nopadding-mobile'>State / Province</label>
                            <input type="text" placeholder="State / Province" />
                            <input type="text" placeholder="ZIP Code" />
                          </div>
                          <div className="form-group delimiter mb-4">
                            <label className='col-md-5 nopadding-mobile'>Country</label>
                            <select defaultValue="">
                              <option value="">Select country</option>
                            </select>
                          </div>
                          <div className="save-button-container">
                            <button type="submit" className="save-button">Save</button>
                          </div>
                        </form>
                      </>
                    )}
                    {selectedOption === 'subscription' && renderSubscriptionContent()}
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

        </>) : (
          <>
            <div className="no-picks-container aistats">
              <div className="no-picks-row">
                <div className="n-picks-inner">
                  <div className='access-denied-symbol'><img src={userlock} /></div>
                  <h5>Access is restricted</h5>
                  <span id="picks-subtitle">Please verify your email to access this page.</span>
                  <NavLink to="/" className="access-denied-btn">Return</NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </motion.main>

      <Modal isOpen={isCancelModalOpen} toggle={toggleCancelModal}>
        <div className="modal-header">
          <div className="modal-title">Cancel subscription</div>
        </div>
        <div className="modal-content">
          <p className="modal-message">
            Cancelling your subscription will result in the immediate loss of the access to our services. Even if you have days left.
          </p>
          <div className="modal-checkbox">
            <input
              type="checkbox"
              id="confirm-cancel"
              checked={isConfirmChecked}
              onChange={(e) => setIsConfirmChecked(e.target.checked)}
            />
            <label htmlFor="confirm-cancel">
              I understand that I will lose access and confirm the cancellation of my subscription
            </label>
          </div>
        </div>
        <div className="modal-buttons">
          <button className="modal-button modal-button-cancel" onClick={toggleCancelModal} disabled={isCancellingSub}>Cancel</button>
          <button
            className="modal-button modal-button-confirm"
            onClick={handleCancelSubscription}
            disabled={!isConfirmChecked || isCancellingSub}
          >
            Confirm
          </button>
        </div>
      </Modal>
      <Modal isOpen={isLoadingModalOpen} toggle={toggleLoadingModal} className='loading'>
        <div className="modal-header">
          <div className="modal-title">
            <img src={loadingGIF} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Settings;