import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './FreeAccess.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Modal from '../../components/Modal/Modal.component';
import Footer from '../../components/Footer/Footer.component';

import { motion } from 'framer-motion';

import numberOneIcon from '../../img/numbers/01.png';
import numberTwoIcon from '../../img/numbers/02.png';
import numberThreeIcon from '../../img/numbers/03.png';

import freePicksUnlockVIP from '../../img/free-picks-unlock-vip.png';

import logoFCMoon from '../../img/promos/fcmoon.png';

const FAQ = ({ faq, index, toggleFAQ }: { faq: any, index: number, toggleFAQ: (index: number) => void }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};


const FreeAccess = () => {
  const [isFreeAccessModalOpen, setIsFreeAccessModalOpen] = useState(false);
  const [isFCMoonlOpen, setIsFCMoonlOpen] = useState(false);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [isFreeAccessValidCheck, setIsFreeAccessValidChecked] = useState(false);

  const [faqs, setFaqs] = useState([
    {
      question: "What is the difference between AI stats and VIP picks?",
      answer:
        "AI stats provide statistical predictions generated by our artificial intelligence algorithms, offering data-driven insights into upcoming matches/events. VIP picks, on the other hand, are handpicked predictions provided by our expert team based on their analysis and expertise.",
      open: true
    },
    {
      question: "How accurate are the AI predictions and VIP picks?",
      answer: "While we strive for accuracy in both AI predictions and VIP picks, it's essential to understand that sports outcomes can be unpredictable. Our AI algorithms and expert team employ advanced analytics and research to provide the best possible predictions, but there's always a degree of uncertainty inherent in sports betting.",
      open: false
    },
    {
      question: "What sports are covered by your predictions?",
      answer: "We cover a wide range of sports, including but not limited to basketball, soccer, hockey, baseball, tennis, and American football. Our goal is to cater to fans of various sports and provide predictions for popular events across different leagues and tournaments.",
      open: false
    },
    {
      question: "How can I access VIP picks?",
      answer: "There are two ways to access VIP picks: by purchasing a paid monthly membership or for Free  by joining a partnered sportsbook casino through our FreeAccess link and making a minimum deposit. Follow the instructions for more information.",
      open: false
    },
    {
      question: "How do I qualify for the one-month free membership with the partnered sportsbook casino?",
      answer: "To qualify for the one-month free membership, you need to join one of our partnered sportsbook casinos through our FreeAccess link and make a minimum deposit as specified. Once you've completed these steps, you'll have to send an email with your account information to get your 1 month free.",
      open: false
    },
    {
      question: "Is there a trial period or money-back guarantee for VIP picks?",
      answer: "At this time, we do not offer a trial period or money-back guarantee for VIP picks. However, we stand behind the quality of our predictions and strive to provide valuable insights to our subscribers. If you have any questions or concerns about our services, please don't hesitate to contact us.",
      open: false
    },
    {
      question: "How can I cancel my subscription or membership?",
      answer: "You can cancel your subscription or membership at any time by accessing your account settings on our website. Follow the prompts to cancel, and your subscription will be terminated directly once you cancel even if you have days left in your current subscription. If you encounter any difficulties, please reach out to our customer support team for assistance.",
      open: false
    },
    {
      question: "Do you offer refunds for subscription fees or memberships?",
      answer: "We operate under a strict 'No Refund Policy' for subscription fees and memberships. Once you've subscribed or purchased a membership, refunds will not be provided for any reason. We encourage users to carefully consider their subscription choices before completing the purchase. If you have questions or concerns about our services, please reach out to our customer support team for assistance before subscribing.",
      open: false
    },
    {
      question: "How can I contact customer support if I have further questions?",
      answer: "If you have any additional questions or need assistance, please don't hesitate to contact our customer support team. You can reach us via email at contact@wagerly.com or through the contact form on our website. We're here to help and ensure you have a positive experience with our services.",
      open: false
    },
  ]);

  const toggleFAQ = (index: any) => {
    setFaqs(
      faqs.map((faq: { question: string; answer: string; open: boolean; }, i: any) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  const toggleFreeAccessModal = () => {
    setIsFreeAccessModalOpen(!isFreeAccessModalOpen);
    if (!isFreeAccessModalOpen) {
      setIsFreeAccessValidChecked(false);
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  };

  const toggleFCMoonModal = () => {
    setIsFCMoonlOpen(!isFCMoonlOpen);
    if (!isFCMoonlOpen) {
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  };

  const handleFreeAccessNext = async () => {
    try {
      toggleFreeAccessModal();
      toggleFCMoonModal()

    } catch (e) {
      console.error(e);
    }
  }

  const handleButtonState = (): boolean => {
    let currentState = (
      !isFreeAccessValidCheck ||
      !(country !== "") ||
      !(state !== "")
    )

    return currentState;
  }

  return (
    <>

      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />
        <div className="page-container">
          <div className="affiliate-container pb-5">

            <section className="page-section free-access-section pt-5 pb-5 mb-5">
              <div className="row hero-row">
                <div className="hero-column-half affiliate-half col order-1 order-md-0">
                  <div className="free-picks-unlock-vip-title">Sign Up to our Partnered casino and get 1 month <span className="free-picks-unlock-vip-orange">FREE</span> of <span className="free-picks-unlock-vip-orange">VIP</span> picks</div>
                  <div className="free-picks-unlock-vip-text">Join our partnered casino and enjoy a month of VIP picks for free! Elevate your gaming strategy with premium insights.</div><br></br>
                  <div className="free-picks-unlock-vip-link">
                    <button onClick={toggleFreeAccessModal} className="free-picks-unlock-vip-button">Sign Up</button>
                  </div>
                </div>
                <div className="hero-column-half col-lg col-12 col-md">
                  <img id="free-picks-unlock-vip-image" src={freePicksUnlockVIP}></img>
                  <button onClick={toggleFreeAccessModal} className="free-picks-unlock-vip-button abs-photo">Unlock VIP access</button>
                </div>
              </div>
            </section>

            <section className="page-section fts mb-5">
              <div className="hero-row medium-row">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps">3 easy steps to get 1 month of free VIP picks</span><br></br><br></br>
                  <div className="three-rows-affiliate d-flex flex-wrap text-left">
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberOneIcon} /></span><br></br>
                      <span className="affiliate-text-title">Sign up to our partnered casino</span><br></br>
                      <span className="affiliate-text">Create your account today to access exclusive AISTATS and VIP picks!</span>
                    </div>
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberTwoIcon} /></span><br></br>
                      <span className="affiliate-text-title">Buy a subscription</span><br></br>
                      <span className="affiliate-text">Fund your account securely to unlock premium insights and strategies!</span>
                    </div>
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberThreeIcon} /></span><br></br>
                      <span className="affiliate-text-title">Send proof of deposit</span><br></br>
                      <span className="affiliate-text">Verify your deposit to activate your bonuses and start receiving VIP picks!</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="page-section get-started-light-green affiliate-get-started mt-5 mb-5">
              <div className="hero-row very-small-row">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps">Are you ready to get 1 month of free VIP Picks?</span><br></br>
                  <span id="affiliate-text">Experience expert insights and elevate your strategy—sign up today!</span><br></br><br></br>
                  <br></br>
                  <button onClick={toggleFreeAccessModal} className="get-started-button">Sign Up</button>
                </div>
              </div>
            </section>
            <section className="page-section mt-5 mb-5">
              <div className="hero-row h-auto">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps" className='faq-title'>Frequently asked questions</span>
                  <span id="affiliate-text" className='faq-text'>Everything you need to know about the free access program.</span>
                  <div className="faqs">
                    {faqs.map((faq: any, index: React.Key | null | undefined) => (
                      <FAQ faq={faq} index={index as number} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer />
        </div>
      </motion.main>
      <Modal className='free-access-modal' isOpen={isFreeAccessModalOpen} toggle={toggleFreeAccessModal}>
        <div className="free-access-modal-top">
          <div className="modal-title">Unlock VIP Access</div>
          <p className="modal-message">Sign up and get 1 month free of VIP picks</p>
        </div>
        <div className="modal-content">
          <form className="affiliate-form">
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>Country</label>
              <input id='country' type="text" placeholder="Country" onChange={(e) => setCountry(e.currentTarget.value)} value={country} />
            </div>
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>State / Province</label>
              <input id='state' type="text" placeholder="State / Province" onChange={(e) => setState(e.currentTarget.value)} value={state} />
            </div>
          </form>
          <div className="modal-checkbox">
            <input
              type="checkbox"
              id="confirm-cancel"
              checked={isFreeAccessValidCheck}
              onChange={(e) => setIsFreeAccessValidChecked(e.target.checked)}
            />
            <label htmlFor="confirm-cancel">
              I confirm that I am of legal age to participate in betting in my country.
            </label>
          </div>
        </div>
        <div className="modal-buttons">
          <button
            className="modal-button modal-button-confirm"
            onClick={handleFreeAccessNext}
            disabled={handleButtonState()}
          >
            Continue
          </button>
        </div>
      </Modal>

      <Modal className='free-access-modal' isOpen={isFCMoonlOpen} toggle={toggleFCMoonModal}>
        <div className='free-access-logo-container'>
          <div className='free-access-logo-inner'>
            <img src={logoFCMoon} />
          </div>
        </div>
        <div className="free-access-modal-top">
          <div className="modal-title">Unlock your free access: Sign up on FCMoon</div>
          <p className="modal-message">You're almost there! To unlock your free access:</p>
        </div>
        <div className="modal-content">
          <form className="affiliate-form">
            <div className="form-group col-md-12">
              <p className="modal-message">1. Sign up on FCMoon casino using this <a>link</a> or simply click on the "Sign Up" button below.</p>
              <p className="modal-message">2. Ensure to deposit a minimum of $50.</p>
              <p className="modal-message">3. Send your username and proof of deposits to <a>contact@wagerly.com</a> and we will respond shortly.</p>
            </div>
          </form>
        </div>
        <div className="modal-buttons">
          <button className="modal-button" onClick={toggleFCMoonModal}>Cancel</button>
          <a className="modal-button modal-button-confirm" href='https://fcmoon-link.com/d2e345b57' target='_blank'>Sign up on FCMoon</a>
        </div>
      </Modal>
    </>
  );
};

export default FreeAccess;
