import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import './MatchPickForm.style.css';

import MatchPick from '../../interfaces/MatchPick.interface';
import App from '../../App';
import convertFileListToBase64 from '../../classes/ImageConverter64';

const MatchPickForm = () => {

  const { control, register, handleSubmit } = useForm<{ picks: MatchPick[] }>({
    defaultValues: {
      picks: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'picks',
  });

  const onSubmit = async (data: { picks: MatchPick[] }) => {
    await Promise.all(
      data.picks.map(async (pick: MatchPick) => {
        pick.date_time = convertCurrentDateTime(pick.date, pick.time);

        if (pick.away_team_image != null && pick.away_team_image != null) {
          try {
            pick.base64_away_team = await convertImageToBase64(pick.away_team_image);
            pick.base64_home_team = await convertImageToBase64(pick.home_team_image);
          } catch (error) {
            console.log("ROOR");
            alert(error);
          }
        }else {
          alert("Upload a home team image and/or a away team image");
        }
      })
    );

    const hasNullAwayTeamImage = data.picks.some((pick: any) => pick.away_team_image === null);
    const hasNullHomeTeamImage = data.picks.some((pick: any) => pick.away_team_image === null);

    if (!hasNullAwayTeamImage && !hasNullHomeTeamImage) {
      try {
        const response = await fetch(`${process.env.REACT_APP_AI_STATS_BACKEND_BASE_URL}/admin-submit-soccer-picks`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ soccer_pick_entries: data.picks }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit picks');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }else {
      alert("Upload a home team image and/or a away team image");
    }
  };

  const convertImageToBase64 = async (image: FileList): Promise<string> => {
    try {
      return await convertFileListToBase64(image);
    }catch (error){
      alert(error)
    }

    return ""
  }

  const convertCurrentDateTime = (date: string, time: string): number => {
    // Get the current date and time
    const now = new Date();
    // Construct a new Date object and get the timestamp in seconds
    return new Date(`${date}T${time}Z`).getTime() / 1000;
  };

  const getCurrentTime = (): string => {
    // Get current date and time
    const now = new Date();

    // Convert both dates to timestamps
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    
    return `${hours}:${minutes}:${seconds}`;
  }

  const getCurrentDate = (): string => {
    const now = new Date();
    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  }

  const getCurrentUnixTime = (): number => {
    const now = new Date();
    return now.getTime() / 1000;
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_AI_STATS_BACKEND_BASE_URL}/soccer-picks`)
      .then(response => response.json())
      .then(data => {
        data.forEach((pick: MatchPick) => {
          append({ ...pick, id: uuidv4() });
        });
      });
  }, [append]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="soccer-pick-form-ap">
      {fields.map((item, index) => (
        <div key={item.id} className="form-group-ap row">
          <div className='col-12'>
            <h3>Match Pick {index + 1}</h3>
          </div>
          <div className='col-lg-2 col-12'>
            <h2 className="admin-page-subtitle-ap">Home team</h2>
            <div className='d-flex flex-wrap'>
              <div className='col-12 flex-column d-flex nopadding'>
                <label>
                  Name:
                  <input {...register(`picks.${index}.home_team`)} placeholder="Manchester City" className="form-control-ap" />
                </label>
                <label>
                  Home Team Image:
                  <input {...register(`picks.${index}.home_team_image`)} type="file" accept=".jpg,.png,.jpeg" placeholder='Image' className="form-control-ap" />
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-2 col-12'>
            <h2 className="admin-page-subtitle-ap">Away team</h2>
            <div className='d-flex flex-wrap'>
              <div className='col-12 flex-column d-flex nopadding'>
                <label>
                  Name:
                  <input {...register(`picks.${index}.away_team`)} placeholder="Bayern Munich" className="form-control-ap" />
                </label>
                <label>
                  Away Team Image:
                  <input {...register(`picks.${index}.away_team_image`)} type="file" accept=".jpg,.png,.jpeg" placeholder='Image' className="form-control-ap" />
                  {/* <input placeholder={register(`picks.${index}.base64_away_team`).name} className="form-control-ap" /> */}
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-12'>
            <h2 className="admin-page-subtitle-ap">Details</h2>
            <div className='d-flex flex-wrap'>
              <div className='col-lg-7 col-12 nopadding'>
                <div className='col-12 nopadding'>
                  <label>
                    League:
                    <input {...register(`picks.${index}.league`)} placeholder="UEFA Champions League" className="form-control-ap" />
                  </label>
                </div>
                <div className='col-12 nopadding'>
                  <label>
                    Match date:
                    <input type="date" {...register(`picks.${index}.date`)} placeholder="YYYY-MM-DD" className="form-control-ap" />
                  </label>
                </div>
                <div className='col-12 nopadding'>
                  <label>
                    Match time:
                    <input step="any" type="time" {...register(`picks.${index}.time`)} placeholder="--:--:--" className="form-control-ap" />
                  </label>
                </div>
              </div>
              <div className='col-lg-5 col-12'>
                <label>
                  Star Rating:
                  <Controller
                    control={control}
                    name={`picks.${index}.star_rating`}
                    render={({ field }) => (
                      <input type="number" {...field} min="1" max="5" placeholder="Star Rating" className="form-control-ap" />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-3 offset-lg-1 col-12 offset-0'>
            <h2 className="admin-page-subtitle-ap">Results</h2>
            <div className='d-flex flex-wrap'>
              <div className='col-12 nopadding'>
                <label>
                  Result text:
                  <input {...register(`picks.${index}.prediction_text`)} placeholder="Manchester City Win" className="form-control-ap" />
                </label>
              </div>
              <div className='col-12 nopadding'>
                <label>
                  Result score:
                  <input {...register(`picks.${index}.prediction_score`)} placeholder="-180" className="form-control-ap" />
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-auto offset-lg-auto offset-0'>
                <label>
                  <input type="checkbox" {...register(`picks.${index}.money_line_pick`)} className="form-control-checkbox-ap" />
                  Money Line Pick
                </label>
              </div>
              <div className='col-auto'>
                <label>
                  <input type="checkbox" {...register(`picks.${index}.best_bet`)} className="form-control-checkbox-ap" />
                  Best Bet
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-12'>
            <h2 className="admin-page-subtitle-ap">Description</h2>
            <label>
              <textarea {...register(`picks.${index}.prediction_description`)} placeholder="The Minnesota Wild are set to face the Philadelphia Flyers in a matchup that promises to be closely contested." className="form-control-ap"></textarea>
            </label>
          </div>
          <div className='col-12'>
            <div className='row'>
              <div className='col-auto offset-lg-auto offset-0'>
                <button type="button" onClick={() => remove(index)} className="remove-button-ap">Remove</button>
              </div>
            </div>
          </div>
        </div>
      ))}
      <h2 className="admin-page-subtitle-ap">Actions</h2>
      <button type="button" className="add-button-ap" onClick={() => append({
        id: uuidv4(),
        home_team: 'Home Team',
        away_team: 'Away Team',
        league: 'League Name',
        base64_home_team: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABFZJREFUeF7tm70vdEEUxs9WIgoJhVBIiGR7GoIoFGg1CrKNQuIrUZGIj/AHSMRHgUJQbKGnIBHRaRUS2Wh2KyQKEdV9c+bN3Pfu/Zq5M2fm7q73VuzdPXOe3znzzFxrMo+Pj05TUxPU1dVBWlcmkwHHcSKHN3H/5+cHPj4+IFMoFBz8oaurCxobG9NiYHXcz89PeHl5ASx8plgsOg0NDeyF3wCBi0etX19ffwG0tbWB90atdoJfY6lU+gcAe7CWIYRpCwCoVQhRhWUASqWS09raWmZCtdQJcVpCO4CTqAUIIg2xAKp9OojEoz4hgGqFICNeGkC1QZAVnwhAtUBIIj4xgEqHkFS8EoBKhaAi3gUQtg8QPZmoDiiKq3JfJxepVSAqKZ2BVYSGfUY3By0AaU8HXfHKHuCvBEUiSTuCakztDkhj20wlnqwDbEKgFE8OwLQnUIs3AsAUBBPitfYBItOiTJgylj9vMhM0sUab6iZvrkYB6AowWXkOwTgAVQg2xBszQd3pYEu8VQCynWBTvHUAIgi2xacCIApCGuKN7gOS7BPwvWl9L2llFYiCwauO99P6UvY/AP+Xo6LWpbrvnfO/bgqEGV6qJsj/P4CqunFx4oSmAcGqB8gIlHkPZaGsAUgiLMl7dWFYAaAiSOUzKjCMA9ARovNZWRhGAVAIoIgRB8MYAMrEKWNZ+ZOYiYRNxDTyNGgqUdGjtOycN9oBJsXzxKnHIPMA6sRUd5NJO4EEgE3x1J2gDSAN8ZQQtACkKZ4KgjKAShBPAUEJQCWJ14WQGEAliteBkAgAlfiHhwcYGBhgeY+MjMDFxQU0NzeXrWDf39+wtLQEg4ODMDk5KVzdvDH7+vpYzI6ODmFMaQBU4p+fn2FiYgL29/ehv7+fJXp/fw87OztQX1/vJoyvT01Nwfn5uRCAP+bR0RFcXV3BwcEBtLS0xMaUAkAlHjNBYYVCAdbW1iKr+v7+DvPz8+xEVy6XEwIIi+nPOSqmEACleNm23t7eht7eXri8vHSnAL6Gbc6nC/48NzcHp6encHh4GDpVvLnv7u4GYgofhijF42AcwNjYGEv6+vo64AHYzicnJ7C8vAyrq6tlwhACXrOzs6wrsIu6u7uZV0TFRA03Nzdwd3cH6+vrgZiRHUAt3gvg9fXVrSSKKhaLzAPw2tjYgOnpaWhvbw+YILYxCkdwW1tbDACHGhdzZWUFhoaGmOdgfK+xWj00FTYFsOKLi4uALfr29ga3t7dlwvyrAE6Bs7MzF6BsTBzj6ekJjo+PYXh42PWVAAATlfe6HVa8s7PTTQABbG5uwt7eHnNtbFP/xavNYeF9XEa5kerELANgWjwmjuaFCXMz4/Pavyr4K+v9fXR01PUAbOskMdE4sQALCwvsmLALwObRWe+mZWZmJrAH8PoFToHx8XHmB3jx/QJfBfL5PGSzWQaBb65EMXt6eph5ukdnf/3h6Uo4Pi/c6xp4Az8+/we7XdFMFUWfkgAAAABJRU5ErkJggg==',
        base64_away_team: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABFZJREFUeF7tm70vdEEUxs9WIgoJhVBIiGR7GoIoFGg1CrKNQuIrUZGIj/AHSMRHgUJQbKGnIBHRaRUS2Wh2KyQKEdV9c+bN3Pfu/Zq5M2fm7q73VuzdPXOe3znzzFxrMo+Pj05TUxPU1dVBWlcmkwHHcSKHN3H/5+cHPj4+IFMoFBz8oaurCxobG9NiYHXcz89PeHl5ASx8plgsOg0NDeyF3wCBi0etX19ffwG0tbWB90atdoJfY6lU+gcAe7CWIYRpCwCoVQhRhWUASqWS09raWmZCtdQJcVpCO4CTqAUIIg2xAKp9OojEoz4hgGqFICNeGkC1QZAVnwhAtUBIIj4xgEqHkFS8EoBKhaAi3gUQtg8QPZmoDiiKq3JfJxepVSAqKZ2BVYSGfUY3By0AaU8HXfHKHuCvBEUiSTuCakztDkhj20wlnqwDbEKgFE8OwLQnUIs3AsAUBBPitfYBItOiTJgylj9vMhM0sUab6iZvrkYB6AowWXkOwTgAVQg2xBszQd3pYEu8VQCynWBTvHUAIgi2xacCIApCGuKN7gOS7BPwvWl9L2llFYiCwauO99P6UvY/AP+Xo6LWpbrvnfO/bgqEGV6qJsj/P4CqunFx4oSmAcGqB8gIlHkPZaGsAUgiLMl7dWFYAaAiSOUzKjCMA9ARovNZWRhGAVAIoIgRB8MYAMrEKWNZ+ZOYiYRNxDTyNGgqUdGjtOycN9oBJsXzxKnHIPMA6sRUd5NJO4EEgE3x1J2gDSAN8ZQQtACkKZ4KgjKAShBPAUEJQCWJ14WQGEAliteBkAgAlfiHhwcYGBhgeY+MjMDFxQU0NzeXrWDf39+wtLQEg4ODMDk5KVzdvDH7+vpYzI6ODmFMaQBU4p+fn2FiYgL29/ehv7+fJXp/fw87OztQX1/vJoyvT01Nwfn5uRCAP+bR0RFcXV3BwcEBtLS0xMaUAkAlHjNBYYVCAdbW1iKr+v7+DvPz8+xEVy6XEwIIi+nPOSqmEACleNm23t7eht7eXri8vHSnAL6Gbc6nC/48NzcHp6encHh4GDpVvLnv7u4GYgofhijF42AcwNjYGEv6+vo64AHYzicnJ7C8vAyrq6tlwhACXrOzs6wrsIu6u7uZV0TFRA03Nzdwd3cH6+vrgZiRHUAt3gvg9fXVrSSKKhaLzAPw2tjYgOnpaWhvbw+YILYxCkdwW1tbDACHGhdzZWUFhoaGmOdgfK+xWj00FTYFsOKLi4uALfr29ga3t7dlwvyrAE6Bs7MzF6BsTBzj6ekJjo+PYXh42PWVAAATlfe6HVa8s7PTTQABbG5uwt7eHnNtbFP/xavNYeF9XEa5kerELANgWjwmjuaFCXMz4/Pavyr4K+v9fXR01PUAbOskMdE4sQALCwvsmLALwObRWe+mZWZmJrAH8PoFToHx8XHmB3jx/QJfBfL5PGSzWQaBb65EMXt6eph5ukdnf/3h6Uo4Pi/c6xp4Az8+/we7XdFMFUWfkgAAAABJRU5ErkJggg==',
        date_time: getCurrentUnixTime(),
        date: getCurrentDate(),
        time: getCurrentTime(),
        home_team_image: null,
        away_team_image: null,
        prediction_text: 'Home Team Wins',
        prediction_score: -180,
        prediction_description: 'Prediction Descript text',
        money_line_pick: true,
        best_bet: true,
        star_rating: 1
      })}>
        Add Match Pick
      </button>
      <button type="submit" className="submit-button-ap">Submit</button>
    </form>
  );
};

export default MatchPickForm;