import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api/axios.instance";

type AffiliateUser = {
  name: string;
  country: string;
  state: string;
  address: string;
  username: string;
  message: string;
};

type CancelSub = {
  subscriptionID: string;
  status: string;
  email: string;
}

export const sendAffiliateEmail = createAsyncThunk("send", async (data: AffiliateUser) => {
  let response = await axiosInstance.post("/subscriptions/affiliate", data);
  return { data: response.data, status: response.status }
});

export const cancelSubscription = createAsyncThunk("cancel", async (data: CancelSub) => {
  const response = await axiosInstance.post("/subscriptions/cancel", data);
  return { data: response.data, status: response.status }
});