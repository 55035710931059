export class Prices {
  constructor() { }

  static prices = [
    {
      title: "AI STATS",
      monthly: { price: 39.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8446" },
      yearly: { price: 31.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8744" },
      features: ["Mathematical sports predictions built with AI", "Access to all sports predictions"],
    },
    {
      title: "VIP",
      monthly: { price: 89.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8447" },
      yearly: { price: 71.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8745" },
      features: ["Best handpicked sport picks & analysis from our sports betting specialists"],
      includes: ["Soccer", "Hockey", "American football", "Baseball", "Basketball", "Tennis"],
    },
    {
      title: "AI STATS + VIP",
      monthly: { price: 119.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8448" },
      yearly: { price: 95.99, link: "https://checkoutwagerlynewsletter.com/checkout/?add-to-cart=8746" },
      features: ["Everything in our AI STATS plan & VIP"],
    },
  ];

  static getDetails(): { title: String, description: String[] }[] {
    return this.prices.map(item => ({
      title: item.title,
      description: [
        ...item.features,
        ...(item.includes || [])
      ]
    }));
  }

  static getDetailsForItem(level: String): String[] {

    const item = this.prices.find((item) => item.title.toLowerCase() === level.toLowerCase());

    if (item) {
      return [
        ...item.features,
        ...(item.includes || []),
      ];
    }

    return ['No features available'];
  }

  static isFree(level: String): Boolean {
    let s = this.prices.find((item) => item.title.toLowerCase() === level.toLowerCase());

    return s === undefined;
  }
}