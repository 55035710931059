import { useEffect, useState } from 'react';

import { useAppSelector } from "../../hooks/redux-hooks";

import axios from 'axios';

import './Casinos.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';

import dollarIcon from '../../img/dollar-icon.png';
import checkMarkIcon from '../../img/checkmark-icon.png';

import logoSportsInteraction from '../../img/promos/sportsinteraction.png';
import logoTonyBet from '../../img/promos/tonybet.png';
import logoBet365 from '../../img/promos/Bet365.png';
import logoAd from '../../img/free-picks-unlock-vip.png';
import logoPinnacle from '../../img/promos/pinnacle.png';
import logoBetVictor from '../../img/promos/betvictor.png';
import logoPowerPlay from '../../img/promos/powerplay.png';
import logoLeoVegas from '../../img/promos/leovegas.png';
import logo888Sport from '../../img/promos/888sport.png';
import logoNeoBet from '../../img/promos/neobet.png';
import logoBetway from '../../img/promos/betway.svg';
import logoFCMoon from '../../img/promos/fcmoon.png';
import logoCBET from '../../img/promos/cbet.png';

import Footer from '../../components/Footer/Footer.component';
import { motion } from 'framer-motion';

const Casinos = () => {

  const [country, setCountry] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);

  const targetRegion = "Ontario";
  const targetCountry = "Canada"

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  useEffect(() => {

    const fetchCountry = async () => {
      try {
        // Fetch the user's IP address
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ip = ipResponse.data.ip;

        // Fetch the country based on the IP address
        const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
        setCountry(countryResponse.data.country_name);
        setRegion(countryResponse.data.region);

      } catch (error) {
        console.error('Failed to fetch country:', error);
        setCountry('Unknown');
      }
    };

    fetchCountry();
  }, [basicUserInfo]);

  return (
    <>

      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />

        <div className="promos-container">
          <div className="promos-row">
            <div className="promos-column">
              <div id="promos-title">
                {region ? (
                  <>
                    <span id="promos-title-span">Best rated sportsbooks in</span> <span id="promos-country">{country}, {region}</span>
                  </>

                ) : (
                  <>
                    <span id="promos-title-span">Best rated sportsbooks in</span> <span id="promos-country">{country}</span>
                  </>
                )}
              </div>
              <div id="promos-list-header">
                <div id="promo-header-content">
                  <div className="promo-sportsbook col-lg-2 col-md-1">
                    <div className="promo-list-table-section ts-sportsbook">Sportsbook</div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="promo-list-table-section ts-bonus">Bonus</div>
                  </div>
                  <div className="col-lg-2 col-auto nopadding-mobile col-md-2">
                    <div className="promo-list-table-section ts-overallrating">Overall Rating</div>
                  </div>
                  <div className="col-md-1 col-lg-2 nopadding-mobile">
                    <div className="promo-list-table-section ts-payoutspeed">Payout Speed</div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-6 nopadding-mobile">
                    <div className="promo-list-table-section ts-benefits">Benefits</div>
                  </div>
                  <div className="col-lg-1 col-md-auto col-6 nopadding">
                    <div className="ts-play">Play Now</div>
                  </div>
                </div>
              </div>
              <div className='promo-items'>
                <div className="promo">
                  <div className="promo-top-left-number"></div>
                  <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoFCMoon}></img></div>
                  <div className="promo-bonus col-lg-3 col-md-3">
                    <span className="promo-bonus-tag">TOP FEATURE</span>
                    <span className="promo-bonus-text">First Bet refunded up to 300$ CAD</span>
                    <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                  </div>
                  <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                    <span className="promo-overallrating-starts">★★★★★</span>
                    <span className="promo-overallrating-number">4.4/5</span>
                    <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://fcmoon-link.com/d2e345b57">FCMoon Review</a></span>
                  </div>
                  <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                    <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                    <span className="promo-payoutspeed-number">1-3 Days</span>
                  </div>
                  <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                  </div>
                  <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                    <a className="promo-buttons-playnow" href="https://fcmoon-link.com/d2e345b57" target='_blank'>Play Now</a>
                  </div>
                </div>

                <div className="promo">
                  <div className="promo-top-left-number"></div>
                  <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoCBET}></img></div>
                  <div className="promo-bonus col-lg-3 col-md-3">
                    <span className="promo-bonus-tag">TOP FEATURE</span>
                    <span className="promo-bonus-text">First bet refunded up to 300$</span>
                    <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                  </div>
                  <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                    <span className="promo-overallrating-starts">★★★★★</span>
                    <span className="promo-overallrating-number">4.4/5</span>
                    <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://balancer-afflink-cbet.com/dc49ab991">CBET Review</a></span>
                  </div>
                  <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                    <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                    <span className="promo-payoutspeed-number">1-3 Days</span>
                  </div>
                  <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                    <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                  </div>
                  <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                    <a className="promo-buttons-playnow" href="https://balancer-afflink-cbet.com/dc49ab991" target='_blank'>Play Now</a>
                  </div>
                </div>
                {(country?.toLowerCase() == targetCountry.toLowerCase()) ? (<>
                  {(region?.toLowerCase() == targetRegion.toLowerCase()) ? (
                    <>
                      <div className="promo promo-ad">
                        <div className="promo-sportsbook col-lg-4 col-md-6 order-lg-0 order-md-0 order-1">
                          <div className="free-picks-unlock-vip-title">Sign Up to our Partnered casino and get 1 month <span className="free-picks-unlock-vip-orange">FREE</span> of <span className="free-picks-unlock-vip-orange">VIP</span> picks</div>
                        </div>
                        <img className="col-lg-4 col-md-4" src={logoAd} />
                        <div className="promo-buttons col-lg-4 nopadding order-lg-0 order-md-0 order-1">
                          {basicUserInfo?.email ? (
                            <>
                              <a href='/pricing' className="promo-buttons-playnow">Pricing</a>
                            </>) : (
                            <>
                              <a href='/register' className="promo-buttons-playnow">Sign up</a>
                            </>)}

                        </div>
                      </div>
                      <div className="promo">
                        <div className="promo-top-left-number"></div>
                        <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoBetway}></img></div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Get your 1st deposit matched up to $500</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://betway.ca/bwp/sports500bonuswelcomeofferon/en-ca/?s=bw203089&a=bfpadid185621">BETWAY ONTARIO WAGERLY Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://betway.ca/bwp/sports500bonuswelcomeofferon/en-ca/?s=bw203089&a=bfpadid185621" target='_blank'>Play Now</a>
                        </div>
                      </div>
                      <div className="promo">
                        <div className="promo-sportsbook col-lg-2 col-md-1 rated">
                          <div className="promo-top-left-number promo-top-left-number-top-rated">TOP-RATED SPORTSBOOK</div>
                          <img src={logoSportsInteraction}></img>
                        </div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">125% deposit match, up to 250$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139709">Sports Interaction Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139709" target='_blank'>Play Now</a>
                        </div>
                      </div>

                      <div className="promo">
                        <div className="promo-top-left-number"></div>
                        <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoPowerPlay}></img></div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18876c_&affid=7006921&siteid=45186&adid=18876&c=yul">PowerPlay Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18876c_&affid=7006921&siteid=45186&adid=18876&c=yul" target='_blank'>Play Now</a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="promo promo-ad">
                        <div className="promo-sportsbook col-lg-4 col-md-6 order-lg-0 order-md-0 order-1">
                          <div className="free-picks-unlock-vip-title">Sign Up to our Partnered casino and get 1 month <span className="free-picks-unlock-vip-orange">FREE</span> of <span className="free-picks-unlock-vip-orange">VIP</span> picks</div>
                        </div>
                        <img className="col-lg-4 col-md-4" src={logoAd} />
                        <div className="promo-buttons col-lg-4 nopadding order-lg-0 order-md-0 order-1">
                          {basicUserInfo?.email ? (
                            <>
                              <a href='/pricing' className="promo-buttons-playnow">Pricing</a>
                            </>) : (
                            <>
                              <a href='/signup' className="promo-buttons-playnow">Sign up</a>
                            </>)}
                        </div>
                      </div>
                      <div className="promo">
                        <div className="promo-top-left-number"></div>
                        <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoBetway}></img></div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Free bet up to 200$ + 50 Bonus Spins</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://betway.com/bwp/casportsoffer/en-ca/?s=bw203089&a=bfpadid185620">Betway Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://betway.com/bwp/casportsoffer/en-ca/?s=bw203089&a=bfpadid185620" target='_blank'>Play Now</a>
                        </div>
                      </div>
                      <div className="promo">

                        <div className="promo-sportsbook col-lg-2 col-md-1 rated">
                          <div className="promo-top-left-number promo-top-left-number-top-rated">TOP-RATED SPORTSBOOK</div>
                          <img src={logoSportsInteraction}></img>
                        </div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% deposit match, up to 250$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139706">Sports Interaction Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139706" target='_blank'>Play Now</a>
                        </div>
                      </div>

                      <div className="promo">
                        <div className="promo-top-left-number"></div>
                        <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoPowerPlay}></img></div>
                        <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                        </div>
                        <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18875c_&affid=7006921&siteid=45186&adid=18875&c=yul">PowerPlay Review</a></span>
                        </div>
                        <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon} /></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                        </div>
                        <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                        </div>
                        <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18875c_&affid=7006921&siteid=45186&adid=18875&c=yul" target='_blank'>Play Now</a>
                        </div>
                      </div>
                    </>
                  )}
                </>) : (<>
                  {/* <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoBetway}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Free bet up to 200$ + 50 Bonus Spins</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://betway.com/bwp/casportsoffer/en-ca/?s=bw203089&a=bfpadid185620">Betway Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://betway.com/bwp/casportsoffer/en-ca/?s=bw203089&a=bfpadid185620" target='_blank'>Play Now</a>
                      </div>
                  </div>
                  <div className="promo">
                      
                      <div className="promo-sportsbook col-lg-2 col-md-1 rated">
                        <div className="promo-top-left-number promo-top-left-number-top-rated">TOP-RATED SPORTSBOOK</div>
                        <img src={logoSportsInteraction}></img>
                      </div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% deposit match, up to 250$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139706">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139706" target='_blank'>Play Now</a>
                      </div>
                  </div>

                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoPowerPlay}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18875c_&affid=7006921&siteid=45186&adid=18875&c=yul">PowerPlay Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a className="promo-buttons-playnow" href="https://www.powerplaybet.com/C.ashx?btag=a_45186b_18875c_&affid=7006921&siteid=45186&adid=18875&c=yul" target='_blank'>Play Now</a>
                      </div>
                  </div> */}
                  <div className="promo promo-ad">
                    <div className="promo-sportsbook col-lg-4 col-md-6 order-lg-0 order-md-0 order-1">
                      <div className="free-picks-unlock-vip-title">Sign Up to our Partnered casino and get 1 month <span className="free-picks-unlock-vip-orange">FREE</span> of <span className="free-picks-unlock-vip-orange">VIP</span> picks</div>
                    </div>
                    <img className="col-lg-4 col-md-4" src={logoAd} />
                    <div className="promo-buttons col-lg-4 nopadding order-lg-0 order-md-0 order-1">
                      {basicUserInfo?.email ? (
                        <>
                          <a href='/pricing' className="promo-buttons-playnow">Pricing</a>
                        </>) : (
                        <>
                          <a href='/register' className="promo-buttons-playnow">Sign up</a>
                        </>)}
                    </div>
                  </div>

                </>)}


                {/*<div className="promo">
                    <div className="promo-top-left-number"></div>
                    <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoTonyBet}></img></div>
                    <div className="promo-bonus col-lg-3 col-md-3">
                        <span className="promo-bonus-tag">TOP FEATURE</span>
                        <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                        <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                    </div>
                    <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                        <span className="promo-overallrating-starts">★★★★★</span>
                        <span className="promo-overallrating-number">4.4/5</span>
                        <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                    </div>
                    <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                        <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                        <span className="promo-payoutspeed-number">1-3 Days</span>
                    </div>
                    <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                    </div>
                    <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                        <a href="#" className="promo-buttons-playnow">Play Now</a>
                    </div>
                </div>

                  <div className="promo">
                    <div className="promo-top-left-number"></div>
                    <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoBet365}></img></div>
                    <div className="promo-bonus col-lg-3 col-md-3">
                        <span className="promo-bonus-tag">TOP FEATURE</span>
                        <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                        <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                    </div>
                    <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                        <span className="promo-overallrating-starts">★★★★★</span>
                        <span className="promo-overallrating-number">4.4/5</span>
                        <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                    </div>
                    <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                        <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                        <span className="promo-payoutspeed-number">1-3 Days</span>
                    </div>
                    <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                        <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                    </div>
                    <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                        <a href="#" className="promo-buttons-playnow">Play Now</a>
                    </div>
                </div> */}
                {/* promo 4 */}
                {/* <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoPinnacle}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div>

                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoBetVictor}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div>

                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoPowerPlay}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div>

                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoLeoVegas}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div>

    
                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logo888Sport}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div>
                  <div className="promo">
                      <div className="promo-top-left-number"></div>
                      <div className="promo-sportsbook col-lg-2 col-md-1"><img src={logoNeoBet}></img></div>
                      <div className="promo-bonus col-lg-3 col-md-3">
                          <span className="promo-bonus-tag">TOP FEATURE</span>
                          <span className="promo-bonus-text">100% Deposit Bonus up to 1000$</span>
                          <span className="promos-bonus-terms">Terms & Conditions Apply</span>
                      </div>
                      <div className="promo-overallrating col-lg-2 col-auto nopadding-mobile col-md-2">
                          <span className="promo-overallrating-starts">★★★★★</span>
                          <span className="promo-overallrating-number">4.4/5</span>
                          <span className="promo-overallrating-review"><a className="promo-overallrating-review" href="https://sports.sportsinteraction.com/en-ca/sports">Sports Interaction Review</a></span>
                      </div>
                      <div className="promo-payoutspeed col-md-1 col-lg-2 nopadding-mobile">
                          <span className="promo-payoutspeed-icon"><img src={dollarIcon}/></span>
                          <span className="promo-payoutspeed-number">1-3 Days</span>
                      </div>
                      <div className="promo-benefits col-lg-2 col-md-2 col-6 nopadding-mobile">
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Terrific live betting</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;No fee banking</span>
                          <span className="promo-benefits-benefit"><span className="promo-benefits-benefit-checkbox"><img src={checkMarkIcon}></img></span>&nbsp;Strong coverage</span>
                      </div>
                      <div className="promo-buttons col-lg-1 col-md-auto col-6 nopadding">
                          <a href="#" className="promo-buttons-playnow">Play Now</a>
                      </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </motion.main>
    </>
  );
};

export default Casinos;
