import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axios.instance";
import { stat } from "fs";

type User = {
  email: string;
  password: string;
};

type NewUser = User & {
  password_repeat: string;
};

type UserBasicInfo = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  verified: boolean;
  subscription_status: string;
};

type UserProfileData = {
  name: string;
  email: string;
};

type VerifyUser = {
  token: string;
}

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  userProfileData?: UserProfileData | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

const initialState: AuthApiState = {
  basicUserInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
  userProfileData: undefined,
  status: "idle",
  error: null,
};

export const login = createAsyncThunk("login", async (data: User) => {

  await delay(2000);

  const response = await axiosInstance.post("/login", data);
  const resData = response.data;

  localStorage.setItem("userInfo", JSON.stringify(resData));

  return resData;
});

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const verifyAccount = createAsyncThunk("verify", async (data: VerifyUser, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/verify-email", data);
    const resData = response.data;

    localStorage.setItem("userInfo", JSON.stringify(resData));

    return resData;

  } catch (error) {
    return rejectWithValue("Verification failed"); // Capture the error
  }
});

export const checkVerification = createAsyncThunk("check", async (email: UserProfileData) => {
  // checkVerification
  const response = await axiosInstance.post("/check-verification", email);
  return { data: response.data, status: response.status }
})

export const register = createAsyncThunk("register", async (data: NewUser, { rejectWithValue }) => {
  await delay(2000); // Simulate delay

  try {
    const response = await axiosInstance.post("/register", data);
    const resData = response.data;

    localStorage.setItem("userInfo", JSON.stringify(resData));

    return resData;

  } catch (error) {
    console.error("Error during registration:", error);
    return rejectWithValue("Registration failed"); // Capture the error
  }
});

export const logout = createAsyncThunk("logout", async () => {
  const response = await axiosInstance.post("/logout", {});
  const resData = response.data;

  localStorage.removeItem("userInfo");

  return resData;
});

export const getUser = createAsyncThunk("users/profile", async (userId: string) => {
  const response = await axiosInstance.get(`/users/${userId}`);
  return response.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status = "loading";
      state.error = null;
    })

      .addCase(login.fulfilled, (state, action: PayloadAction<UserBasicInfo>) => {
        state.status = "idle";
        state.basicUserInfo = action.payload;
      })

      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Login failed";
      })

      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })

      .addCase(register.fulfilled, (state, action: PayloadAction<UserBasicInfo>) => {
        state.status = "idle";
        state.basicUserInfo = action.payload;
      })

      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.toString() || "Registration failed";
      })

      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })

      .addCase(logout.fulfilled, (state) => {
        state.status = "idle";
        state.basicUserInfo = null;
        localStorage.removeItem("userInfo");
      })

      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })

      .addCase(getUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userProfileData = action.payload;
      })

      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Get user profile data failed";
      })

      .addCase(verifyAccount.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })

      .addCase(verifyAccount.fulfilled, (state, action: PayloadAction<UserBasicInfo>) => {
        state.status = "idle";
        state.basicUserInfo = action.payload;
      });
  },
});

export default authSlice.reducer;