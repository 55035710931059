import { useAppSelector } from "../../hooks/redux-hooks";

import './Email.style.css';

import logo from '../../img/wagerly-logo.png';

const Email = () => {

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  return (
    <>
      <div id="app-email-container">
        <div id="center-email-container">
          <div id="email-form-div">
            <div className="logo-email">
              <img src={logo} alt="wagerly" />
            </div>
            {basicUserInfo?.email ? (
              <>
                <div id="welcome-text">
                  <span id="welcome-text-span">Confirm your email</span>
                </div>
                <div id="email-text">
                  <span className="email-text-span">An confirmation email has been send to: <span id="basicuserinfo-email">{basicUserInfo.email}</span></span>
                  <span className="email-text-span">Please check your email and click on the confirmation link to activate your account fully!</span>
                </div>
              </>
            ) : (
              <div id="">No user</div>
            )}
            <button className="email-button" onClick={event => window.location.href = '/'}>Home</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
