import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import MenuBar from '../../components/MenuBar/MenuBar.component';

import './TOS.style.css';

import SocialMedia from '../../components/SocialMedia/SocialMedia.component';
import Footer from '../../components/Footer/Footer.component';
import { motion } from 'framer-motion';

const Term = ({ term, index }: { term: any, index: number }) => {
  return (
    <div className="col-lg-9 col-md-12 col-12">
      <p className="terms-warning-title trmm-5">{index + 1}. {term.title}</p>
      <p className="terms-content-txt">{term.body}</p>
      {term.subTerms != null ?
        <ul className="terms-list">
          {term.subTerms.map((elem: String) => (
            <li className="terms-item">{elem}</li>
          ))}
        </ul> : null}
    </div>
  );
};

const TOS = () => {

  const [terms, setTerm] = useState([
    {
      "title": "Definitions",
      "body": "Describes key definitions relevant to the terms of service, including the User, Services, and Site.",
      "subTerms": [
        "Any person who purchases prognostic services from Conseils Yulpronos Inc. or accesses or uses its website.",
        "Advice or prognoses provided by Wagerly, indicating on which team or professional to bet on online sports betting sites.",
        "The website of Wagerly."
      ]
    },
    {
      "title": "Acceptance of Terms",
      "body": "By purchasing our Services and using our Site, the User agrees to comply with the applicable laws in Quebec and Canada, and the conditions outlined below. This constitutes a legal agreement between the User and Conseils Yulpronos Inc.",
      "subTerms": null
    },
    {
      "title": "Service Description",
      "body": "Wagerly offers prognostic services through its Site and social networks. Users are responsible for how they use this information.",
      "subTerms": null
    },
    {
      "title": "Age Requirement",
      "body": "Users must be 18 years of age or older and meet the legal age requirement in their country of residence.",
      "subTerms": null
    },
    {
      "title": "Personal Information",
      "body": "Users must ensure that all personal information provided to Wagerly is accurate, up-to-date, and complete. Users are responsible for protecting their personal information.",
      "subTerms": null
    },
    {
      "title": "User Obligations",
      "body": "By using the Site and purchasing Services, the User agrees to certain obligations.",
      "subTerms": [
        "Not use the Site in a way that could harm its resources, integrity, or security, or violate any laws.",
        "Not access non-public areas of the Site or Wagerly's computer systems.",
        "Not explore or test the vulnerability of the Site.",
        "Not decrypt, decompile, or disassemble any software used for the Site.",
        "Not transmit messages anonymously or under a false name, any illegal or objectionable information, or anything containing viruses or destructive elements.",
      ]
    },
    {
      "title": "Privacy Policy",
      "body": "Wagerly takes reasonable steps to manage Users' personal information in accordance with its Privacy Policy and applicable legal requirements.",
      "subTerms": null
    },
    {
      "title": "Pricing and Changes",
      "body": "All prices are in Canadian dollars unless otherwise indicated and exclude taxes. Prices may be modified at any time without prior notice.",
      "subTerms": [
        "Changes in prices by third parties like PayPal and Stripe are beyond Wagerly's control."
      ]
    },
    {
      "title": "Payment Terms",
      "body": "Users can choose from various payment packages. Payments are made at the beginning of each period and renewed automatically.",
      "subTerms": [
        "Monthly, quarterly, semi-annual, or annual packages.",
        "Credit card and bank account through Visa, MasterCard, and PayPal."
      ]
    },
    {
      "title": "Cancellation and Refund",
      "body": "Users can terminate their account at any time via the 'My Account' section or PayPal. Wagerly will not charge for the subsequent period upon termination. No refunds are provided once payment is made.",
      "subTerms": null
    },
    {
      "title": "Intellectual Property",
      "body": "The Site and its content are the exclusive property of Wagerly. Users are granted the right to browse the Site but cannot reproduce or distribute its content without prior written permission.",
      "subTerms": [
        "The use of the Services is limited to personal and non-commercial use unless expressly permitted by Wagerly."
      ]
    },
    {
      "title": "Disclaimers",
      "body": "The Site is provided 'as is' without any warranty. Wagerly is not liable for any errors, omissions, or interruptions on the Site, promotional offers by bookmakers, the use of information from the Site, or the success of prognoses. The User acknowledges that the use of the Site and Services is at their own risk.",
      "subTerms": null
    },
    {
      "title": "Limitation of Liability",
      "body": "Wagerly's liability is limited to the amount paid by the User for the Services.",
      "subTerms": null
    },
    {
      "title": "Indemnity",
      "body": "Users agree to indemnify Wagerly against all claims, damages, and expenses arising from their use of the Site or breach of these Terms.",
      "subTerms": null
    },
    {
      "title": "Membership and Use of the Site",
      "body": "Wagerly reserves the right to refuse membership and modify, suspend, or terminate access to the Site and Services.",
      "subTerms": [
        "Wagerly is not responsible for promoting gambling addiction. Users with gambling problems are encouraged to seek help."
      ]
    },
    {
      "title": "General",
      "body": "These Terms constitute the entire agreement between the User and Wagerly. Users cannot assign their rights or obligations under these Terms without prior written permission from Wagerly. If any provision of these Terms is found invalid, the remaining provisions will continue to be in effect.",
      "subTerms": null
    },
    {
      "title": "Modification of the Terms of Use",
      "body": "Wagerly reserves the unilateral right to make changes to these Terms of Use at any time and without notice. Wagerly will take reasonable steps, if necessary, to inform its Users of any material changes. Any User will be bound by these changes; Wagerly reserves the right to terminate these Terms of Use.",
      "subTerms": null
    },
    {
      "title": "Jurisdiction",
      "body": "The Terms of Use and the User’s use of the Site are governed by the laws of the Province of Quebec and the laws of Canada applicable thereto. If the User’s use of the Site should give rise to a dispute, it must be resolved according to the laws applicable in the Province of Quebec and Canada, by a competent court of the judicial district of Montreal, Province of Quebec.",
      "subTerms": null
    },
    {
      "title": "Contact",
      "body": "If you have any questions regarding these Terms of Use, we invite you to contact us using the following communication method: support@wagerly.com",
      "subTerms": null
    }
  ]);

  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />
        <div className="page-container">
          <section className="terms-container">
            <div className="row">
              <div className="col-lg-9">
                <div className="trmm-5">
                  <h1 className="terms-title">Terms & Conditions</h1>
                </div>
              </div>
              {terms.map((term: any, index: React.Key | null | undefined) => (
                <Term term={term} index={index as number} key={index} />
              ))}
            </div>
          </section>
          <Footer />
        </div>
      </motion.main>
    </>
  );
};

export default TOS;