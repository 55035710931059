import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

import axios from 'axios';

import MatchPick from '../../interfaces/MatchPick.interface';

import './Picks.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';

import vipKingIcon from '../../img/picks-icons/vip-king.png';

import soccerIcon from '../../img/sports-icon/soccer-grey.png';

import sportsInteractionLogo from '../../img/promos/sportsinteraction.png';
import cbetLogo from '../../img/ads/cbet.png';
import betwayLogo from '../../img/promos/betway.svg';
import powerplayLogo from '../../img/promos/powerplay.png';

import userlock from '../../img/user-lock.svg';

import { motion } from 'framer-motion';

import { checkVerification } from "../../slices/auth.slice";

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

const Picks = () => {
  const [picksList, setPicksList] = useState<MatchPick[]>([]);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [country, setCountry] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);

  const targetRegion = "Ontario";
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchVerification = async () => {
      if (basicUserInfo) {
        try {
          const res = await dispatch(
            checkVerification({
              name: basicUserInfo.first_name,
              email: basicUserInfo.email
            })
          ).unwrap();

          let response = res.data.message ?? false;

          setEmailVerified(response)

          return response
        } catch (e) {
          console.error(e);
          setEmailVerified(false);

          return false
        }
      } else {
        setEmailVerified(false);
        return false
      }
    }

    const fetchMatchList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_AI_STATS_BACKEND_BASE_URL}/soccer-picks`);
        const data = await response.json();
        setPicksList(data);
      } catch (error) {
        console.error('Error fetching match list:', error);
      }
    };

    const fetchCountry = async () => {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ip = ipResponse.data.ip;

        const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
        setCountry(countryResponse.data.country_name);
        setRegion(countryResponse.data.region);
      } catch (error) {
        console.error('Failed to fetch country:', error);
        setCountry('Unknown');
      }
    };

    const canAccessVIP = async () => {
      if (basicUserInfo) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/${basicUserInfo.id}`);
          if (!response.ok) throw new Error('Failed to fetch subscription data');

          const data = await response.json();

          const isVerified = await fetchVerification()

          setIsAllowed(isSubscriptionIDValid(data.subscription.productId) && isVerified);
        } catch (err) {
          console.error(err);
        }
      }
      setIsLoading(false); // Stop loading when check is complete
    };

    function isSubscriptionIDValid(id: number) {
      return id === 8447 || id === 8448 || id === 8745 || id === 8746;
    }

    fetchVerification();
    // Fetch all necessary data
    canAccessVIP();
    fetchCountry();
    fetchMatchList();
  }, [basicUserInfo]);


  if (isLoading) {
    return <div></div>;
  }

  return (
    <motion.main
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -100 }}
      transition={{ duration: 0.5 }}
    >
      <MenuBar />
      <div className="page-container">
        <div className="picks-container">
          <div className="picks-row">
            <div className="col-lg-9 col-md-8 col-12">
              <div id="picks-title">
                <span id="picks-title-span">VIP picks</span>
              </div>
              <div id="picks-subtitle">
                <span id="picks-subtitle-span">Your exclusive gateway to expertly curated selections, frequent updates, and strategic insights for top-tier sports betting success across all major sports.</span>
              </div>
              {isAllowed ? (
                <>
                  {picksList.length > 0 ? (
                    <>
                      <div className="picks-container-group">
                        {picksList.map((item) => (
                          <div key={item.id} className="pick">
                            <img className="vip-king" src={vipKingIcon} alt="VIP King Icon" />
                            <div className="pick-tags">
                              <div className="pick-tags-left">
                                {item.money_line_pick ? (<><div className="pick-tag-money-line-pick">MONEY LINE PICK</div></>) : (<></>)}
                                {item.best_bet ? (<>
                                  <div className="pick-tag-best-best">BEST BET</div>
                                  </>) : (<></>)}
                              </div>
                              <div className="pick-tag-stars">
                                {'★'.repeat(item.star_rating)}
                              </div>
                            </div>
                            <div className="pick-date">
                              <img className="pick-data-sports-icon" src={soccerIcon} alt="Soccer Icon" />
                              {item.league} | {formatDate(item.date_time)}
                            </div>
                            <div className="pick-match">
                              <img className="pick-match-team-icon" src={`${item.base64_home_team}`} alt="Home Team Icon" width="64px" height="64px" />
                              <img className="pick-match-team-icon" src={`${item.base64_away_team}`} alt="Away Team Icon" width="64px" height="64px" />
                            </div>
                            <div className="pick-result-container">
                              <div className="pick-result">{item.prediction_text}</div>
                              <div className="pick-match-teams-text">{item.home_team} - {item.away_team}</div>
                            </div>
                            <div className="pick-odds">{item.prediction_score}</div>
                            <div className="pick-text">{item.prediction_description}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (<>
                    <div className="no-picks-container">
                      <div className="no-picks-row">
                        <div className="n-picks-inner">
                          <div className='access-denied-symbol'><img src={userlock} /></div>
                          <h5>No Picks Available</h5>
                          <span id="picks-subtitle">We haven't made any pick at the moment. Please check again later.</span>
                        </div>
                      </div>
                    </div>
                  </>)}
                </>
              ) : (
                <>
                  <div className="no-picks-container">
                    <div className="no-picks-row">
                      <div className="n-picks-inner">
                        <div className='access-denied-symbol'><img src={userlock} /></div>
                        <h5>Access is denied</h5>
                        {basicUserInfo ? (<>
                          {!isEmailVerified ? (<>
                            <span id="picks-subtitle">Please verify your email address</span>
                          </>) : (<>
                            <span id="picks-subtitle">You do not have access to this page</span>
                          </>)}
                          {!isEmailVerified ? (<>
                            <NavLink to="/" className="access-denied-btn">Return</NavLink>
                          </>) : (<>
                            <NavLink to="/pricing" className="access-denied-btn">Buy plan</NavLink>
                          </>)}
                        </>) : (<>
                          <span id="picks-subtitle">You do not have access to this page</span>
                          <NavLink to="/login" className="access-denied-btn">Login</NavLink>
                        </>)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="picks-ads-container-group">
                <div className="picks-bonuses-title">
                  <span className="picks-bonuses-title-span">Bonuses</span>
                  <NavLink to="/casinos" className="picks-bonuses-link">View all</NavLink>
                </div>
                <div className="pick-ad">
                  <div className="pick-ad-logo">
                    <img className="pick-ad-logo-img" src={cbetLogo}></img>
                  </div>
                  <div className="pick-ad-text">
                    <span className="pick-ad-text-span">First bet refunded up to 300$</span>
                    <a className="pick-ad-button" href='https://balancer-afflink-cbet.com/dc49ab991' target='_blank'>Claim now</a>
                  </div>
                </div>
                <div className="pick-ad">
                  <div className="pick-ad-logo">
                    <img className="pick-ad-logo-img" src={sportsInteractionLogo}></img>
                  </div>
                  <div className="pick-ad-text">
                    <span className="pick-ad-text-span">Deposit Bonus up to 1000$</span>
                    <a className="pick-ad-button" href='https://mediaserver.entainpartners.com/renderBanner.do?zoneId=2139706' target='_blank'>Claim now</a>
                  </div>
                </div>
                <div className="pick-ad">
                  <div className="pick-ad-logo">
                    <img className="pick-ad-logo-img" src={betwayLogo}></img>
                  </div>
                  <div className="pick-ad-text">
                    <span className="pick-ad-text-span">Get your 1st deposit matched up to 500$</span>
                    <a className="pick-ad-button" href={(region?.toLowerCase() == targetRegion.toLowerCase()) ? ("https://betway.ca/bwp/sports500bonuswelcomeofferon/en-ca/?s=bw203089&a=bfpadid185621") : ("https://betway.com/bwp/casportsoffer/en-ca/?s=bw203089&a=bfpadid185620")} target='_blank'>Claim now</a>
                  </div>
                </div>
                <div className="pick-ad">
                  <div className="pick-ad-logo">
                    <img className="pick-ad-logo-img" src={powerplayLogo}></img>
                  </div>
                  <div className="pick-ad-text">
                    <span className="pick-ad-text-span">Deposit Bonus up to 1000$</span>
                    <a className="pick-ad-button" href={(region?.toLowerCase() == targetRegion.toLowerCase()) ? ("https://www.powerplaybet.com/C.ashx?btag=a_45186b_18876c_&affid=7006921&siteid=45186&adid=18876&c=yul") : ("https://www.powerplaybet.com/C.ashx?btag=a_45186b_18875c_&affid=7006921&siteid=45186&adid=18875&c=yul")} target='_blank'>Claim now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </motion.main>
  );
};

export default Picks;

