// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page-ap {
  margin: 20px;
  margin-top: 5em;
}

.admin-page-title-ap {
  color: #000;
  font-weight: 600;
}

.admin-page-subtitle-ap {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase !important;
  margin-bottom: 0;
  text-transform: none;
  line-height: 1;
  letter-spacing: normal;
  position: relative;
  display: block;
  margin-top: 4em;
  transition: all .3s linear;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,oCAAoC;EACpC,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,eAAe;EAIf,0BAA0B;AAC5B","sourcesContent":[".admin-page-ap {\n  margin: 20px;\n  margin-top: 5em;\n}\n\n.admin-page-title-ap {\n  color: #000;\n  font-weight: 600;\n}\n\n.admin-page-subtitle-ap {\n  font-weight: 600;\n  font-size: 12px;\n  text-transform: uppercase !important;\n  margin-bottom: 0;\n  text-transform: none;\n  line-height: 1;\n  letter-spacing: normal;\n  position: relative;\n  display: block;\n  margin-top: 4em;\n  -moz-transition: all .3s linear;\n  -o-transition: all .3s linear;\n  -webkit-transition: all .3s linear;\n  transition: all .3s linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
