// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible {
  position: relative;
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  padding-left: 0px;
}

.collapsible__toggle {
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  color: #131520;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.collapsible__arrow {
  float: right;
  margin-left: 5px;
  transition: transform 0.3s ease-in-out;
}

.collapsible__arrow.open {
  transform: rotate(-90deg);
}

.collapsible__content {
  transition: max-height 0.2s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/components/CollapsibleCountry/CollapsibleCountry.style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".collapsible {\n  position: relative;\n  width: 100%;\n  padding: 10px;\n  padding-top: 10px;\n  padding-left: 0px;\n}\n\n.collapsible__toggle {\n  width: 100%;\n  border: none;\n  background: transparent;\n  text-align: left;\n  color: #131520;\n  font-size: 16px;\n  font-weight: 500;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n}\n\n.collapsible__arrow {\n  float: right;\n  margin-left: 5px;\n  transition: transform 0.3s ease-in-out;\n}\n\n.collapsible__arrow.open {\n  transform: rotate(-90deg);\n}\n\n.collapsible__content {\n  transition: max-height 0.2s ease-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
