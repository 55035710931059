import { useEffect, useState } from 'react';

import { NavLink, Navigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getUser, login } from "../../slices/auth.slice";

import './Login.style.css';

import logo from '../../img/wagerly-logo.png';

import Modal from '../../components/Modal/Modal.component';
import Loader from '../../components/Loader/Loader.component';

import { motion } from 'framer-motion';

const Login = (props: any) => {

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const status = useAppSelector((state) => state.auth.status);

  useEffect(() => {

    if (basicUserInfo) {
      dispatch(getUser(basicUserInfo.id));
    }
  }, [basicUserInfo]);

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  const handleLogin = async () => {

    if (!validateEmail(email)) {
      setErrorModalMessage("This email is invalid.");
      toggleErrorModal();
      return;
    }

    if (password.length < 8) {
      setErrorModalMessage("Password must be at least 8 characters!");
      toggleErrorModal();
      return;
    }
    if (email && password) {
      try {
        await dispatch(
          login({
            email,
            password,
          })
        ).unwrap().then((payload) => {
          //nothing
        }).catch((err) => {
          setErrorModalMessage("There was an error with the username or password.");
          toggleErrorModal();
        });
      } catch (e) {
        //console.error(e);
      }
    } else {
      // Show an error message.
    }
  };

  const validateEmail = (email: String) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/*basicUserInfo && <Navigate to="/" />*/}
        <Modal isOpen={showErrorModal} toggle={toggleErrorModal}>
          <span>{errorModalMessage}</span>
        </Modal>
        <div id="app-container">
          {status === "loading" && (
            <Loader />
          )}
          <div id="login-form-div">
            <div className="logo-login">
              <img src={logo} alt="wagerly" />
            </div>
            {basicUserInfo?.email ? (
              <>
                <span style={{ color: 'white' }}>Redirecting...</span>
                <Navigate to="/" />
              </>
            ) : (
              <>
                <div id="welcome-text">
                  <span id="welcome-text-span">Login to your account</span>
                </div>
                <div id="login-text">
                  <span id="login-text-span">Access your account and view your insights!</span>
                </div>
                <form className='login-form' onSubmit={(event) => {
                  event.preventDefault();
                  handleLogin();
                }}>
                  <input
                    id="username-input"
                    type="email"
                    name="username"
                    placeholder="iwant@bets.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    id="password-input"
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button type="submit" id="login-button-login-page">Login</button>
                  <NavLink to="/" id="signup-back-button">Back</NavLink>
                </form>
                <div id="signup-text">
                  <span id="signup-text-span">No account?</span>&nbsp;&nbsp;
                  <a id="signup-text-link" href="/signup">Sign Up</a>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.main>
    </>
  );
};

export default Login;