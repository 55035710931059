import React, { ReactNode, useEffect, useState } from "react";
import "./Modal.style.css";

interface ModalType {
  isOpen: boolean;
  toggle: () => void;
  children?: ReactNode;
  className?: string;
}

const Modal = (props: ModalType) => {
  const allowClickCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
  }

  return (
    <>
      {props.isOpen && (
        <div className={`modal-overlay ${props.className ? props.className : ''}`} onClick={props.toggle}>
          <div onClick={allowClickCancel} className="modal-box">
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
