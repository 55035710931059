import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

import { NavLink } from 'react-router-dom';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';

import userlock from '../../img/user-lock.svg';
import denied from '../../img/denied-icon.svg';
import './AiStats.style.css';
import { motion } from 'framer-motion';
import CollapsibleCountry from '../../components/CollapsibleCountry/CollapsibleCountry.component';
import Loader from '../../components/Loader/Loader.component';
import { checkVerification } from "../../slices/auth.slice";

interface sportMath {
  date_time: number;
  home_team: string;
  away_team: string;

  country: string;
  league: string;
  country_image: string;
}

export interface wdw_MathData extends sportMath {
  win_home: number;
  draw: number;
  win_away: number;
  forebet_prediction_wdw: string;
  forebet_prediction_wdw_score: string;
  forebet_wdw_average_goals: number;
  forebet_coefficient: string;
}

export interface under_over_2_5_MathData extends sportMath {

  under_probability: number;
  over_probability: number;
  forebet_prediction_over_under: string;
  forebet_prediction_over_under_score: string;
  forebet_over_under_average_goals: number;
  forebet_coefficient: string;
}

export interface ht_ft_MathData extends sportMath {
  ht_ft_home: number;
  ht_ft_draw: number;
  ht_ft_away: number;
  forebet_prediction_ht: string;
  forebet_prediction_ft: string;
  forebet_prediction_ht_ft_score: number;
  forebet_coefficient: string;
}

export interface corners_MathData extends sportMath {
  under_9_5: number;
  over_9_5: number;
  forebet_prediction_under_9_5_over_9_5: string;
  forebet_prediction_under_9_5_over_9_5_average_corners: string;
  forebet_coefficient: string;
}

export interface btts_MathData extends sportMath {
  btts_no: number;
  btts_yes: number;
  win_away: number;
  forebet_prediction_btts: string;
  forebet_prediction_btts_score: string;
  forebet_prediction_btts_average_goals: number;
  forebet_coefficient: string;
}

export interface basketball_MathData extends sportMath {
  forebet_1: number;
  forebet_2: number;
  forebet_prediction: number;
  home_score: number;
  away_score: number;
  forebet_average_points: number;
  forebet_q1_home: number;
  forebet_q1_away: number;
  forebet_q2_home: number;
  forebet_q2_away: number;
  forebet_q3_home: number;
  forebet_q3_away: number;
  forebet_q4_home: number;
  forebet_q4_away: number;
  final_score_home: number;
  final_score_away: number;

  forebet_coefficient: string;
}

export interface tennis_MathData extends sportMath {

  forebet_1: number;
  forebet_2: number;
  forebet_prediction: number;
  home_score: number;
  away_score: number;
  forebet_average_games: number;
  forebet_s1: string;
  forebet_s2: string;
  forebet_s3: string;
  forebet_s4: string;
  forebet_s5: string;
}

export interface hockey_MathData extends sportMath {
  forebet_1: number;
  forebet_2: number;
  forebet_prediction: number;
  home_score: number;
  away_score: number;
  forebet_average_goals: number;
}

export interface americanFootball_MathData extends sportMath {
  forebet_1: number;
  forebet_2: number;
  forebet_prediction: number;
  home_score: number;
  away_score: number;
  forebet_average_points: number;
  forebet_q1_home: number;
  forebet_q1_away: number;
  forebet_q2_home: number;
  forebet_q2_away: number;
  forebet_q3_home: number;
  forebet_q3_away: number;
  forebet_q4_home: number;
  forebet_q4_away: number;
  final_score_home: number;
  final_score_away: number;
}

export interface baseball_MathData extends sportMath {
  forebet_1: number;
  forebet_2: number;
  forebet_prediction: number;
  home_score: number;
  away_score: number;
  forebet_average_runs: number;
  home_inning_1_9: number;
  away_inning_1_9: number;
  home_extra_innings: number;
  away_extra_innings: number;
  final_score_home: number;
  final_score_away: number;
}

//countries
interface Country {
  name: string;
  leagues: string[];
}

const AiStats = () => {

  const dispatch = useAppDispatch();

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const [isAllowed, setIsAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  const [selectedSport, setSelectedSport] = useState('1'); // Soccer, Hockey, American Football, Tennis, Basketball, Baseball

  const [selectedStat, setSelectedStat] = useState('1'); // 1 = W/D/W, 2 = Under/Over 2.5, 3 = HT/FT, 4 = Corners, 5 = Both to score
  const [wdw_data, set_wdw_Data] = useState<wdw_MathData[]>([]);
  const [under_over_2_5_data, set_under_over_2_5_Data] = useState<under_over_2_5_MathData[]>([]);
  const [ht_ft_data, set_ht_ft_Data] = useState<ht_ft_MathData[]>([]);
  const [corners_data, set_corners_Data] = useState<corners_MathData[]>([]);
  const [btts_data, set_btts_Data] = useState<btts_MathData[]>([]);

  const [basketball_data, set_basketball_Data] = useState<basketball_MathData[]>([]);
  const [tennis_data, set_tennis_Data] = useState<tennis_MathData[]>([]);
  const [americanFootball_data, set_americanFootball_Data] = useState<americanFootball_MathData[]>([]);
  const [baseball_data, set_baseball_Data] = useState<baseball_MathData[]>([]);
  const [hockey_data, set_hockey_Data] = useState<hockey_MathData[]>([]);

  const [isEmailVerified, setEmailVerified] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  //countries
  const [countries, setCountries] = useState<Country[]>([]);

  const baseURL = process.env.REACT_APP_AI_STATS_BACKEND_BASE_URL;

  const defaultTime = 1708468332;

  const grouped_wdw_data = wdw_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as wdw_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: wdw_MathData[], country_image: string }>);

  const grouped_under_over_2_5_data = under_over_2_5_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as under_over_2_5_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: under_over_2_5_MathData[], country_image: string }>);

  const grouped_ht_ft_data = ht_ft_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as ht_ft_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: ht_ft_MathData[], country_image: string }>);

  const grouped_corners_data = corners_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as corners_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: corners_MathData[], country_image: string }>);

  const grouped_btts_data = btts_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as btts_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: btts_MathData[], country_image: string }>);


  // soccer, hockey, american football, tennis, basketball, baseball

  const grouped_hockey_data = hockey_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as hockey_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: hockey_MathData[], country_image: string }>);


  const grouped_american_football_data = americanFootball_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as americanFootball_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: americanFootball_MathData[], country_image: string }>);

  const grouped_tennis_data = tennis_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as tennis_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: tennis_MathData[], country_image: string }>);

  const grouped_basketball_data = basketball_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as basketball_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: basketball_MathData[], country_image: string }>);

  const grouped_baseball_data = baseball_data.reduce((acc, match) => {
    if (!acc[match.league]) {
      acc[match.league] = { matches: [] as baseball_MathData[], country_image: match.country_image as string };
    }
    acc[match.league].matches.push(match);
    return acc;
  }, {} as Record<string, { matches: baseball_MathData[], country_image: string }>);

  const format_countries = (data: any[]): Country[] => {
    const formattedData: Country[] = [];
    data.forEach(item => {
      const existingCountry = formattedData.find(country => country.name === item.country);
      if (existingCountry) {
        existingCountry.leagues.push(...item.leagues.split(','));
      } else {
        formattedData.push({
          name: item.country,
          leagues: item.leagues.split(',')
        });
      }
    });
    return formattedData;
  };

  function processCountryData(data: Country[]): Country[] {
    return data.map(country => ({
      ...country,
      leagues: Array.from(new Set(country.leagues))
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      await delay(1000);
      try {
        handleSportSelection("1");
        setFirstLoad(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      handleCountries();
    };

    const fetchVerification = async () => {
      if (basicUserInfo) {
        try {
          const res = await dispatch(
            checkVerification({
              name: basicUserInfo.first_name,
              email: basicUserInfo.email
            })
          ).unwrap();

          let response = res.data.message ?? false;

          setEmailVerified(response)

          return response
        } catch (e) {
          console.error(e);
          setEmailVerified(false);

          return false
        }
      } else {
        setEmailVerified(false);
        return false
      }
    }

    const canAccessVIP = async () => {
      if (basicUserInfo) {

        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/${basicUserInfo.id}`);
          if (!response.ok) throw new Error('Failed to fetch subscription data');

          const data = await response.json();

          const isVerified = await fetchVerification()

          setIsAllowed(isSubscriptionIDValid(data.subscription.productId) && isVerified);
        } catch (err) {
          console.error(err);
        }
      }

      setIsLoading(false); // Stop loading when check is complete
    };

    function isSubscriptionIDValid(id: number) {
      return id === 8446 || id === 8448 || id === 8744 || id === 8746;
    }

    fetchVerification();
    fetchData();
    canAccessVIP();
  }, [basicUserInfo]);

  function getCurrentTime(): number {
    return Math.floor(Date.now() / 1000);
  }

  const handleCountries = async () => {
    try {
      let response = await getEndpointData('/api/forebet/countries/day/')
      // removing all the duplicates
      const formated_data: Country[] = format_countries(await response.json());
      // removing all the empty country names
      const filtered_formated_data = formated_data.filter(country => country.name.trim() !== "''");
      // removing all the single quotes from the country name and league name
      filtered_formated_data.forEach(country => {
        country.name = country.name.replace(/'/g, '');
        country.leagues = country.leagues.map(league => league.replace(/'/g, ''));
      });
      setCountries(processCountryData(filtered_formated_data));
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const handleSportSelection = async (option: string) => {
    try {
      if (option === '1') {
        handleDataSelector(option);
      } else if (option === '2') {
        let endpointURL = '/api/forebet/matches/hockey/day/';
        let latestURL = '/api/forebet/matches/hockey/latest/all/';

        const response = await getEndpointData(endpointURL);
        let r = await response.json();
        let data: hockey_MathData[] = r as hockey_MathData[];
        if (data.length == 0) {
          const response = await getEndpointDataAll(latestURL);
          const _data: hockey_MathData[] = await response.json();

          data = _data;
        }

        //reorder data by alphabetical league name
        data.sort((a, b) => a.league.localeCompare(b.league));

        set_hockey_Data(data);
      } else if (option === '3') {
        let endpointURL = '/api/forebet/matches/american-football/day/';
        let latestURL = '/api/forebet/matches/american-football/latest/all/';

        const response = await getEndpointData(endpointURL);
        let data: americanFootball_MathData[] = await response.json();

        if (data.length == 0) {
          
          const response = await getEndpointDataAll(latestURL);
          const _data: americanFootball_MathData[] = await response.json();

          data = _data;
        }
        //reorder data by alphabetical league name
        data.sort((a, b) => a.league.localeCompare(b.league));

        set_americanFootball_Data(data);
      } else if (option === '4') {
        let endpointURL = '/api/forebet/matches/tennis/day/';
        let latestURL = '/api/forebet/matches/tennis/latest/all/';

        const response = await getEndpointData(endpointURL);
        let data: tennis_MathData[] = await response.json();

        if (data.length == 0) {
          
          const response = await getEndpointDataAll(latestURL);
          const _data: tennis_MathData[] = await response.json();

          data = _data;
        }
        //reorder data by alphabetical league name
        data.sort((a, b) => a.league.localeCompare(b.league));

        set_tennis_Data(data);

      } else if (option === '5') {
        let endpointURL = '/api/forebet/matches/basketball/day/';
        let latestURL = '/api/forebet/matches/basketball/latest/all/';

        const response = await getEndpointData(endpointURL);
        let data: basketball_MathData[] = await response.json();

        if (data.length == 0) {
          
          const response = await getEndpointDataAll(latestURL);
          const _data: basketball_MathData[] = await response.json();

          data = _data;
        }
        //reorder data by alphabetical league name
        data.sort((a, b) => a.league.localeCompare(b.league));

        set_basketball_Data(data);
      } else if (option === '6') {
        let endpointURL = '/api/forebet/matches/baseball/day/';
        let latestURL = '/api/forebet/matches/baseball/latest/all/';

        const response = await getEndpointData(endpointURL);
        let data: baseball_MathData[] = await response.json();

        if (data.length == 0) {
          
          const response = await getEndpointDataAll(latestURL);
          const _data: baseball_MathData[] = await response.json();

          data = _data;
        }
        //reorder data by alphabetical league name
        data.sort((a, b) => a.league.localeCompare(b.league));

        set_baseball_Data(data);
      }
      setSelectedSport(option);
    } catch (error) {
      console.log("An error occurred fetching matches", error);
    }
  }

  async function getEndpointData(link: string, time?: number): Promise<Response> {
    if (time) {
      return await fetch(baseURL + link + time);
    }
    return await fetch(baseURL + link + getCurrentTime());
  }

  async function getEndpointDataAll(link: string): Promise<Response> {
    return await fetch(baseURL + link);
  }

  const handleDataSelector = async (option: string) => {

    //setFirstLoad(true);
    if (selectedSport == '1') {
      try {

        if (option === '1') {
          const endpointURL = '/api/forebet/matches/wdw/day/';

          const response = await getEndpointData(endpointURL);
          var data: wdw_MathData[] = await response.json();

          if (data.length == 0) {
            
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: wdw_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_wdw_Data(data);

        } else if (option === '2') {
          const endpointURL = '/api/forebet/matches/under_over/day/';

          const response = await getEndpointData(endpointURL);
          let data: under_over_2_5_MathData[] = await response.json();

          if (data.length == 0) {
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: under_over_2_5_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_under_over_2_5_Data(data);
        } else if (option === '3') {
          const endpointURL = '/api/forebet/matches/ht_ft/day/';

          const response = await getEndpointData(endpointURL);
          let data: ht_ft_MathData[] = await response.json();

          if (data.length == 0) {
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: ht_ft_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_ht_ft_Data(data);
        } else if (option === '5') {
          const endpointURL = '/api/forebet/matches/btts/day/';

          const response = await getEndpointData(endpointURL);
          let data: btts_MathData[] = await response.json();

          if (data.length == 0) {
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: btts_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_btts_Data(data);
        } else if (option === '4') {
          const endpointURL = '/api/forebet/matches/corners/day/';

          const response = await getEndpointData(endpointURL);
          let data: corners_MathData[] = await response.json();

          if (data.length == 0) {
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: corners_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_corners_Data(data);
        } else {
          const endpointURL = '/api/forebet/matches/wdw/day/';

          const response = await getEndpointData(endpointURL);
          let data: wdw_MathData[] = await response.json();

          if (data.length == 0) {
            const response = await getEndpointData(endpointURL, defaultTime);
            const _data: wdw_MathData[] = await response.json();

            data = _data;
          }

          //reorder data by alphabetical league name
          data.sort((a, b) => a.league.localeCompare(b.league));

          set_wdw_Data(data);
        }

        setSelectedStat(option);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>

      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />

        {isAllowed ? (
          <>
            {firstLoad ? (
              <Loader />
            ) : (
              <></>
            )}
            <div className="aistats-container container-fluid">
              <div className="aistats-row row">
                <div className="aistats-column column-left col-md-2">
                  {/* <div className="popular-leagues">
                  <div className="popular-leagues-title">POPULAR LEAGUES</div>
                  <div className="popular-leagues-list">
                    <div className="popular-league-box">
                      <span className="popular-league-name">UEFA Champions League</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">UEFA Europa League</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Premier League</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">LaLiga</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Bundesliga</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Serie A</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Ligue 1</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Eredivisie</span>
                      <span className="match-count">0</span>
                    </div>
                    <div className="popular-league-box">
                      <span className="popular-league-name">Liga Portugal</span>
                      <span className="match-count">0</span>
                    </div>
                  </div>
                </div> */}
                  <div className="popular-countries">
                    <div className="popular-countries-title">COUNTRIES</div>
                    <div className="popular-countries-list">
                      {countries.map((country, index) => (
                        <CollapsibleCountry key={index} title={country.name} enabled={selectedSport != '1'}>
                          <ul className="country-collapsible-list">
                            {country.leagues.map((league, i) => (
                              <li className={`country-league${selectedSport != '1' ? ' disabled' : ''}`} key={i}><a href={"#" + league.replaceAll("'", "").replaceAll(" ", "")}>{league}</a></li>
                            ))}
                          </ul>
                        </CollapsibleCountry>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="aistats-column column-80 table-responsive-md col-md-10">
                  <div id="sports-selector">
                    <div className={`sport-box${selectedSport === '1' ? ' selected' : ''}`} onClick={() => handleSportSelection('1')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="none" height="256" width="256" />
                          <path d="M231.8,134.8a4.8,4.8,0,0,0,0-1.2c.1-1.9.2-3.7.2-5.6a103.2,103.2,0,0,0-23-65.1,5.5,5.5,0,0,0-1.4-1.7,103.9,103.9,0,0,0-41.1-29.8l-1.1-.4a103.4,103.4,0,0,0-74.8,0l-1.1.4A103.9,103.9,0,0,0,48.4,61.2,5.5,5.5,0,0,0,47,62.9,103.2,103.2,0,0,0,24,128c0,1.9.1,3.7.2,5.6a4.8,4.8,0,0,0,0,1.2,104.2,104.2,0,0,0,15.7,48.4,9.9,9.9,0,0,0,1.1,1.7,104.3,104.3,0,0,0,60.3,43.6h.3a104.2,104.2,0,0,0,52.8,0h.3A104.3,104.3,0,0,0,215,184.9a9.9,9.9,0,0,0,1.1-1.7A104.2,104.2,0,0,0,231.8,134.8ZM68.5,117.1l13.2,4.3,12.7,39.2-8.1,11.2H51.7a86.2,86.2,0,0,1-11.2-34.3Zm119,0,28,20.4a86.2,86.2,0,0,1-11.2,34.3H169.7l-8.1-11.2,12.7-39.2ZM193.2,69l-10.7,32.9-13.2,4.3L136,81.9V68.1l28-20.4A87,87,0,0,1,193.2,69ZM92,47.7l28,20.4V81.9L86.7,106.2l-13.2-4.3L62.8,69A87,87,0,0,1,92,47.7Zm18,166.4L99.3,181.2l8.1-11.2h41.2l8.1,11.2L146,214.1a86.2,86.2,0,0,1-36,0Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>Soccer</span>
                    </div>
                    <div className={`sport-box${selectedSport === '2' ? ' selected' : ''}`} onClick={() => handleSportSelection('2')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M25.5 89.8438C25.5 68.6748 71.2729 51.5 127.75 51.5C184.227 51.5 230 68.6748 230 89.8438C230 111.013 184.227 128.188 127.75 128.188C71.2729 128.188 25.5 111.013 25.5 89.8438ZM127.391 147C87.9684 147 48.1588 139.131 25.5 122.676V166.531C25.5 187.7 71.2729 204.875 127.75 204.875C184.227 204.875 230 187.7 230 166.531V122.676C207.353 139.131 167.532 147 127.391 147Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>Hockey</span>
                    </div>
                    <div className={`sport-box${selectedSport === '3' ? ' selected' : ''}`} onClick={() => handleSportSelection('3')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="none" height="256" width="256" />
                          <path d="M226,56.3A32.3,32.3,0,0,0,199.7,30c-31.4-5.3-90.4-7.7-133.9,35.8S24.7,168.3,30,199.7A32.3,32.3,0,0,0,56.3,226a206.3,206.3,0,0,0,33.3,2.8c31.1,0,69.8-7.7,100.6-38.6C233.7,146.7,231.3,87.7,226,56.3ZM59,210.2A15.9,15.9,0,0,1,45.8,197a178.7,178.7,0,0,1-2.7-30.6l46.5,46.5A179.5,179.5,0,0,1,59,210.2ZM165.7,101.7l-15.1,15,9,9a8,8,0,0,1,0,11.3,8.3,8.3,0,0,1-5.7,2.3,8,8,0,0,1-5.6-2.3l-9-9L128,139.3l9,9a8,8,0,0,1,0,11.3,7.8,7.8,0,0,1-5.7,2.3,7.6,7.6,0,0,1-5.6-2.3l-9-9-15,15.1a8.2,8.2,0,0,1-11.4,0,8.1,8.1,0,0,1,0-11.4l15.1-15-9-9A8,8,0,0,1,107.7,119l9,9L128,116.7l-9-9a8,8,0,0,1,11.3-11.3l9,9,15-15.1a8.1,8.1,0,0,1,11.4,11.4Zm.7-58.6A179.5,179.5,0,0,1,197,45.8,15.9,15.9,0,0,1,210.2,59a178.7,178.7,0,0,1,2.7,30.6Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>American Football</span>
                    </div>
                    <div className={`sport-box${selectedSport === '4' ? ' selected' : ''}`} onClick={() => handleSportSelection('4')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="none" height="256" width="256" />
                          <path d="M224,136h3.3a4,4,0,0,1,3.9,4.5,104.2,104.2,0,0,1-94.8,91.1A88,88,0,0,1,224,136Zm-73.5,14.5A102.9,102.9,0,0,1,224,120h3.1a4,4,0,0,0,4.1-4.4,104.1,104.1,0,0,0-90.9-90.9,4,4,0,0,0-4.4,4.1,103.7,103.7,0,0,1-30.4,76.7A102.9,102.9,0,0,1,32,136H28.9a4,4,0,0,0-4.1,4.4,104.1,104.1,0,0,0,90.9,90.9,4,4,0,0,0,4.4-4.1A103.7,103.7,0,0,1,150.5,150.5ZM94.2,94.2a87.6,87.6,0,0,0,25.7-65.7,4,4,0,0,0-4.5-3.7,104,104,0,0,0-90.6,90.6,4,4,0,0,0,3.8,4.5A87.8,87.8,0,0,0,94.2,94.2Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>Tennis</span>
                    </div>
                    <div className={`sport-box${selectedSport === '5' ? ' selected' : ''}`} onClick={() => handleSportSelection('5')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="none" height="256" width="256" />
                          <path d="M63.6,46.4a104.3,104.3,0,0,1,52-21.7,4,4,0,0,1,4.4,4V120H95.7A104.1,104.1,0,0,0,63.4,52.4,3.9,3.9,0,0,1,63.6,46.4ZM46,64.1a104.8,104.8,0,0,0-21.3,51.4,4.1,4.1,0,0,0,4,4.5H79.6A88,88,0,0,0,51.9,63.6,4,4,0,0,0,46,64.1ZM192.4,46.4a104.3,104.3,0,0,0-52-21.7,4,4,0,0,0-4.4,4V120h24.3a104.1,104.1,0,0,1,32.3-67.6A3.9,3.9,0,0,0,192.4,46.4Zm38.9,69.1A104.8,104.8,0,0,0,210,64.1a4,4,0,0,0-5.9-.5A88,88,0,0,0,176.4,120h50.9A4.1,4.1,0,0,0,231.3,115.5Zm-206.6,25A104.8,104.8,0,0,0,46,191.9a4,4,0,0,0,5.9.5A88,88,0,0,0,79.6,136H28.7A4.1,4.1,0,0,0,24.7,140.5ZM210,191.9a104.8,104.8,0,0,0,21.3-51.4,4.1,4.1,0,0,0-4-4.5H176.4a88,88,0,0,0,27.7,56.4A4,4,0,0,0,210,191.9ZM63.6,209.6a104.3,104.3,0,0,0,52,21.7,4,4,0,0,0,4.4-4V136H95.7a104.1,104.1,0,0,1-32.3,67.6A3.9,3.9,0,0,0,63.6,209.6ZM160.3,136H136v91.3a4,4,0,0,0,4.4,4,104.3,104.3,0,0,0,52-21.7,3.9,3.9,0,0,0,.2-6A104.1,104.1,0,0,1,160.3,136Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>Basketball</span>
                    </div>
                    <div className={`sport-box${selectedSport === '6' ? ' selected' : ''}`} onClick={() => handleSportSelection('6')}>
                      <span className="sport-box-icon">
                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <rect fill="none" height="256" width="256" />
                          <path d="M51.9,63.9a4,4,0,0,0-6,.3,103.9,103.9,0,0,0,0,127.6,4,4,0,0,0,6,.3l7.3-7.3a8,8,0,0,1,6.2-2.8,7.9,7.9,0,0,1,5.2,2,8.2,8.2,0,0,1,.6,11.4,99.9,99.9,0,0,1-7.8,8.2,3.9,3.9,0,0,0,.2,6,103.8,103.8,0,0,0,128.8,0,3.9,3.9,0,0,0,.2-6,103.3,103.3,0,0,1-7.9-8.3,8,8,0,1,1,12.1-10.5l7.3,7.3a4,4,0,0,0,6-.3,103.9,103.9,0,0,0,0-127.6,4,4,0,0,0-6-.3l-7.3,7.3a8.1,8.1,0,0,1-6.1,2.8,7.6,7.6,0,0,1-5.3-2,8.2,8.2,0,0,1-.6-11.4,99.9,99.9,0,0,1,7.8-8.2,3.9,3.9,0,0,0-.2-6,103.8,103.8,0,0,0-128.8,0,3.9,3.9,0,0,0-.2,6,99.9,99.9,0,0,1,7.8,8.2A8,8,0,0,1,65.3,74h0a8.1,8.1,0,0,1-6.1-2.8ZM79.2,88.2a9.6,9.6,0,0,1,2.8-.7,8.1,8.1,0,0,1,7.9,5.3,105.7,105.7,0,0,1,4.4,16.5,8,8,0,0,1-5.9,9.4,6.3,6.3,0,0,1-1.9.3h0a8,8,0,0,1-7.9-6.6,83.8,83.8,0,0,0-3.7-13.9C73.5,94.5,75.2,89.8,79.2,88.2Zm15.1,58.5a105.7,105.7,0,0,1-4.4,16.5,8.1,8.1,0,0,1-7.6,5.3h0a7,7,0,0,1-3.1-.7,8.2,8.2,0,0,1-4.3-10.3,83.8,83.8,0,0,0,3.7-13.9,8.1,8.1,0,0,1,7.7-6.6l2,.2A8.2,8.2,0,0,1,94.3,146.7Zm82.5,21.1a7,7,0,0,1-3.1.7,8.1,8.1,0,0,1-7.6-5.3,105.7,105.7,0,0,1-4.4-16.5,8.2,8.2,0,0,1,5.4-9.3,8.1,8.1,0,0,1,10.3,6.2,83.8,83.8,0,0,0,3.7,13.9A8.2,8.2,0,0,1,176.8,167.8Zm3.6-68a23.6,23.6,0,0,0-2.9,11.4,8,8,0,0,1-8,7.8l-1.4-.2c-4.3-.7-7.2-5.3-6.4-9.6A109,109,0,0,1,166,93.1a8.3,8.3,0,0,1,9.8-5.3A8,8,0,0,1,180.4,99.8Z" fill="#000000" />
                        </svg>
                      </span>
                      <span>Baseball</span>
                    </div>
                  </div>
                  <div id="stats-selector">
                    <div className={`stat-box${selectedStat === '1' ? ' selected' : ''}${selectedSport != '1' ? ' disabled' : ''}`} onClick={() => handleDataSelector('1')}>W/D/W</div>
                    <div className={`stat-box${selectedStat === '2' ? ' selected' : ''}${selectedSport != '1' ? ' disabled' : ''}`} onClick={() => handleDataSelector('2')}>Under / Over 2.5</div>
                    <div className={`stat-box${selectedStat === '3' ? ' selected' : ''}${selectedSport != '1' ? ' disabled' : ''}`} onClick={() => handleDataSelector('3')}>HT / FT</div>
                    <div className={`stat-box${selectedStat === '4' ? ' selected' : ''}${selectedSport != '1' ? ' disabled' : ''}`} onClick={() => handleDataSelector('4')}>Corners</div>
                    <div className={`stat-box${selectedStat === '5' ? ' selected' : ''}${selectedSport != '1' ? ' disabled' : ''}`} onClick={() => handleDataSelector('5')}>Both to score</div>
                  </div>
                  {firstLoad ? (
                    <div className="first-loader"></div>
                  ) : (
                    <></>
                  )
                  }

                  {
                    (() => {
                      if (selectedSport === '1') {
                        if (selectedStat === '1') {
                          return (<>
                            {Object.entries(grouped_wdw_data).map(([league, { matches, country_image }]) => {
                              if (matches.length == 0) {
                                return (<>
                                  <div className="no-matches-container aistats">
                                    <div className="no-picks-row">
                                      <div className="n-picks-inner">
                                        <div className='access-denied-symbol'><img src={denied} /></div>
                                        <h5>No W/D/W matches available</h5>
                                        <span id="picks-subtitle">Check back later for further updates</span>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              } else {
                                return (
                                  <>
                                    <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                    <table className="wagerly-table">
                                      <thead>
                                        <tr>
                                          <th>Event</th>
                                          <th>Probability</th>
                                          <th className="header-title-center">Prediction</th>
                                          <th className="header-title-center">Score</th>
                                          <th className="header-title-center">Coefficient</th>
                                          <th className='d-none d-md-block'>Avg. Goals</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches.map((match, index) => {

                                          const values = [Number(match.win_home), Number(match.draw), Number(match.win_away)].sort((a: number, b: number) => b - a);
                                          const highest = values[0];
                                          const middle = values[1];
                                          const lowest = values[2];

                                          return (
                                            <tr key={index} className="free-table-element">
                                              <td className='match-date'>
                                                <div className="match-time">
                                                  {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                  <br></br>
                                                  {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                                </div>
                                                <div className="match-teams">
                                                  <span className="match-teams-team">{match.home_team}</span>
                                                  <span className="match-teams-team">{match.away_team}</span>
                                                </div>
                                              </td>
                                              <td className='match-probs'>
                                                <div className='table-inner-content'>
                                                  <div className={match.win_home === highest ? 'match-probability p-green' : match.win_home === lowest ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                    {match.win_home}%
                                                  </div>
                                                  <div className={match.draw === highest ? 'match-probability p-green' : match.draw === lowest ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                    {match.draw}%
                                                  </div>
                                                  <div className={match.win_away === highest ? 'match-probability p-green' : match.win_away === lowest ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                    {match.win_away}%
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_wdw}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_wdw_score}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_coefficient ?? "N/A"}</div></td>
                                              <td className='center d-none d-md-table-cell'><div className='table-inner-content'>{match.forebet_wdw_average_goals}</div></td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            })}
                          </>)
                        } else if (selectedStat === '2') {
                          return (<>
                            {Object.entries(grouped_under_over_2_5_data).map(([league, { matches, country_image }]) => {
                              if (matches.length == 0) {
                                return (<>
                                  <div className="no-matches-container aistats">
                                    <div className="no-picks-row">
                                      <div className="n-picks-inner">
                                        <div className='access-denied-symbol'><img src={denied} /></div>
                                        <h5>No Under / Over 2.5 matches available</h5>
                                        <span id="picks-subtitle">Check back later for further updates</span>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              } else {
                                return (
                                  <>
                                    <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                    <table className="wagerly-table">
                                      <thead>
                                        <tr>
                                          <th>Event</th>
                                          <th>Probability</th>
                                          <th className="header-title-center">Prediction</th>
                                          <th className="header-title-center">Score</th>
                                          <th className="header-title-center">Coefficient</th>
                                          <th>Avg. Goals</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches.map((match, index) => {

                                          const values = [Number(match.under_probability), Number(match.over_probability)].sort((a: number, b: number) => b - a);
                                          const highest = values[0];
                                          const lowest = values[1];

                                          return (
                                            <tr key={index} className="free-table-element">
                                              <td className='match-date'>
                                                <div className="match-time">
                                                  {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                  <br></br>
                                                  {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                                </div>
                                                <div className="match-teams">
                                                  <span className="match-teams-team">{match.home_team}</span>
                                                  <span className="match-teams-team">{match.away_team}</span>
                                                </div>
                                              </td>
                                              <td className='match-probs'>
                                                <div className='table-inner-content'>
                                                  <div className={match.under_probability === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.under_probability}%
                                                  </div>
                                                  <div className={match.over_probability === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.over_probability}%
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_over_under}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_over_under_score}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_coefficient ?? "N/A"}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_over_under_average_goals}</div></td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                )
                              }
                            })}
                          </>)
                        } else if (selectedStat === '3') {
                          return (<>
                            {Object.entries(grouped_ht_ft_data).map(([league, { matches, country_image }]) => {
                              if (matches.length == 0) {
                                return (<>
                                  <div className="no-matches-container aistats">
                                    <div className="no-picks-row">
                                      <div className="n-picks-inner">
                                        <div className='access-denied-symbol'><img src={denied} /></div>
                                        <h5>No HT/FT matches available</h5>
                                        <span id="picks-subtitle">Check back later for further updates</span>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              } else {
                                return (
                                  <>
                                    <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                    <table className="wagerly-table">
                                      <thead>
                                        <tr>
                                          <th>Event</th>
                                          <th>Probability</th>
                                          <th className="header-title-center">Prediction</th>
                                          <th className="header-title-center">Score</th>
                                          <th className="header-title-center">Coefficient</th>
                                          <th>Avg. Goals</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches.map((match, index) => {

                                          const values = [Number(match.ht_ft_home), Number(match.ht_ft_draw), Number(match.ht_ft_away)];

                                          return (
                                            <tr key={index} className="free-table-element">
                                              <td className='match-date'>
                                                <div className="match-time">
                                                  {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                  <br></br>
                                                  {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                                </div>
                                                <div className="match-teams">
                                                  <span className="match-teams-team">{match.home_team}</span>
                                                  <span className="match-teams-team">{match.away_team}</span>
                                                </div>
                                              </td>
                                              <td className='match-probs'>
                                                <div className='table-inner-content'>
                                                  <div className={match.ht_ft_home > 75 ? 'match-probability p-green' : match.ht_ft_home < 25 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                    {match.ht_ft_home}%
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_ht}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_ft}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_coefficient ?? "N/A"}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_ht_ft_score}</div></td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            })}
                          </>)
                        } else if (selectedStat === '4') {
                          return (<>
                            {Object.entries(grouped_corners_data).map(([league, { matches, country_image }]) => {
                              if (matches.length == 0) {
                                return (<>
                                  <div className="no-matches-container aistats">
                                    <div className="no-picks-row">
                                      <div className="n-picks-inner">
                                        <div className='access-denied-symbol'><img src={denied} /></div>
                                        <h5>No Corners Data matches available</h5>
                                        <span id="picks-subtitle">Check back later for further updates</span>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              } else {
                                return (
                                  <>
                                    <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                    <table className="wagerly-table">
                                      <thead>
                                        <tr>
                                          <th>Event</th>
                                          <th>Probability</th>
                                          <th className="header-title-center">Prediction</th>
                                          <th className="header-title-center">Coefficient</th>
                                          <th>Avg. Corners</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches.map((match, index) => {
                                          const values = [Number(match.under_9_5), Number(match.over_9_5)].sort((a: number, b: number) => b - a);
                                          const highest = values[0];
                                          const lowest = values[1];

                                          return (
                                            <tr key={index} className="free-table-element">
                                              <td className='match-date'>
                                                <div className="match-time">
                                                  {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                  <br></br>
                                                  {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                                </div>
                                                <div className="match-teams">
                                                  <span className="match-teams-team">{match.home_team}</span>
                                                  <span className="match-teams-team">{match.away_team}</span>
                                                </div>
                                              </td>
                                              <td className='match-probs'>
                                                <div className='table-inner-content'>
                                                  <div className={match.under_9_5 === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.under_9_5}%
                                                  </div>
                                                  <div className={match.over_9_5 === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.over_9_5}%
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_under_9_5_over_9_5}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_coefficient ?? "N/A"}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_under_9_5_over_9_5_average_corners}</div></td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            })}
                          </>)
                        } else if (selectedStat === '5') {
                          return (<>
                            {Object.entries(grouped_btts_data).map(([league, { matches, country_image }]) => {
                              if (matches.length == 0) {
                                return (<>
                                  <div className="no-matches-container aistats">
                                    <div className="no-picks-row">
                                      <div className="n-picks-inner">
                                        <div className='access-denied-symbol'><img src={denied} /></div>
                                        <h5>No Both to score matches available</h5>
                                        <span id="picks-subtitle">Check back later for further updates</span>
                                      </div>
                                    </div>
                                  </div>
                                </>)
                              } else {
                                return (
                                  <>
                                    <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                    <table className="wagerly-table">
                                      <thead>
                                        <tr>
                                          <th>Event</th>
                                          <th>Probability</th>
                                          <th className="header-title-center">Prediction</th>
                                          <th className="header-title-center">Score</th>
                                          <th className="header-title-center">Coefficient</th>
                                          <th>Avg. Goals</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {matches.map((match, index) => {
                                          const values = [Number(match.btts_no), Number(match.btts_yes)].sort((a: number, b: number) => b - a);
                                          const highest = values[0];
                                          const lowest = values[1];

                                          return (
                                            <tr key={index} className="free-table-element">
                                              <td className='match-date'>
                                                <div className="match-time">
                                                  {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                  <br></br>
                                                  {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                                </div>
                                                <div className="match-teams">
                                                  <span className="match-teams-team">{match.home_team}</span>
                                                  <span className="match-teams-team">{match.away_team}</span>
                                                </div>
                                              </td>
                                              <td className='match-probs'>
                                                <div className='table-inner-content'>
                                                  <div className={match.btts_no === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.btts_no}%
                                                  </div>
                                                  <div className={match.btts_yes === highest ? 'match-probability p-green' : 'match-probability p-red'}>
                                                    {match.btts_yes}%
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_btts}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_btts_score}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_coefficient ?? "N/A"}</div></td>
                                              <td className='center'><div className='table-inner-content'>{match.forebet_prediction_btts_average_goals}</div></td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </>
                                );
                              }
                            })}
                          </>)
                        }
                      } else if (selectedSport === '2') {
                        return (<>
                          {Object.entries(grouped_hockey_data).map(([league, { matches, country_image }]) => {
                            if (matches.length == 0) {
                              return (<>
                                <div className="no-matches-container aistats">
                                  <div className="no-picks-row">
                                    <div className="n-picks-inner">
                                      <div className='access-denied-symbol'><img src={denied} /></div>
                                      <h5>No Hockey matches available</h5>
                                      <span id="picks-subtitle">Check back later for further updates</span>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            } else {
                              return (
                                <>
                                  <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                  <table className="wagerly-table">
                                    <thead>
                                      <tr>
                                        <th>Event</th>
                                        <th>Probability</th>
                                        <th className="header-title-center">Prediction</th>
                                        <th className="header-title-center">Score</th>
                                        <th>Avg. Goals</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {matches.map((match, index) => {
                                        return (
                                          <tr key={index} className="free-table-element">
                                            <td className='match-date'>
                                              <div className="match-time">
                                                {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                <br></br>
                                                {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                              </div>
                                              <div className="match-teams">
                                                <span className="match-teams-team">{match.home_team}</span>
                                                <span className="match-teams-team">{match.away_team}</span>
                                              </div>
                                            </td>
                                            <td className='match-probs'>
                                              <div className='table-inner-content'>
                                                <div className={match.forebet_1 > 50 ? 'match-probability p-green' : match.forebet_1 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_1}%
                                                </div>
                                                <div className={match.forebet_2 > 50 ? 'match-probability p-green' : match.forebet_2 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_2}%
                                                </div>
                                              </div>
                                            </td>
                                            <td className='center'><div className='table-inner-content'>{match.forebet_prediction}</div></td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.home_score}</div>
                                              <div className='table-inner-content'>{match.away_score}</div>
                                            </td>
                                            <td className='center'><div className='table-inner-content'>{match.forebet_average_goals}</div></td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            }
                          })}
                        </>)
                      } else if (selectedSport === '3') {
                        return (<>
                          {Object.entries(grouped_american_football_data).map(([league, { matches, country_image }]) => {
                            if (matches.length == 0) {
                              return (<>
                                <div className="no-matches-container aistats">
                                  <div className="no-picks-row">
                                    <div className="n-picks-inner">
                                      <div className='access-denied-symbol'><img src={denied} /></div>
                                      <h5>No NFL matches available</h5>
                                      <span id="picks-subtitle">Check back later for further updates</span>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            } else {
                              return (
                                <>
                                  <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                  <table className="wagerly-table">
                                    <thead>
                                      <tr>
                                        <th>Event</th>
                                        <th>Probability</th>
                                        <th className="header-title-center">Prediction</th>
                                        <th>Avg. Points</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {matches.map((match, index) => {
                                        return (
                                          <tr key={index} className="free-table-element">
                                            <td className='match-date'>
                                              <div className="match-time">
                                                {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                <br></br>
                                                {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                              </div>
                                              <div className="match-teams">
                                                <span className="match-teams-team">{match.home_team}</span>
                                                <span className="match-teams-team">{match.away_team}</span>
                                              </div>
                                            </td>
                                            <td className='match-probs'>
                                              <div className='table-inner-content'>
                                                <div className={match.forebet_1 > 50 ? 'match-probability p-green' : match.forebet_1 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_1}%
                                                </div>
                                                <div className={match.forebet_2 > 50 ? 'match-probability p-green' : match.forebet_2 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_2}%
                                                </div>
                                              </div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_prediction}</div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_average_points}</div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            }
                          })}
                        </>)
                      } else if (selectedSport === '4') {
                        return (<>
                          {Object.entries(grouped_tennis_data).map(([league, { matches, country_image }]) => {
                            if (matches.length == 0) {
                              return (<>
                                <div className="no-matches-container aistats">
                                  <div className="no-picks-row">
                                    <div className="n-picks-inner">
                                      <div className='access-denied-symbol'><img src={denied} /></div>
                                      <h5>No Tennis matches available</h5>
                                      <span id="picks-subtitle">Check back later for further updates</span>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            } else {
                              return (
                                <>
                                  <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "") === '' ? "Tennis" : league.replaceAll("'", "")}</thead>
                                  <table className="wagerly-table">
                                    <thead>
                                      <tr>
                                        <th>Event</th>
                                        <th>Probability</th>
                                        <th className="header-title-center">Prediction</th>
                                        <th>Avg. Game Set</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {matches.map((match, index) => {
                                        return (
                                          <tr key={index} className="free-table-element">
                                            <td className='match-date'>
                                              <div className="match-time">
                                                {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                <br></br>
                                                {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                              </div>
                                              <div className="match-teams">
                                                <span className="match-teams-team">{match.home_team}</span>
                                                <span className="match-teams-team">{match.away_team}</span>
                                              </div>
                                            </td>
                                            <td className='match-probs'>
                                              <div className='table-inner-content'>
                                                <div className={match.forebet_1 > 50 ? 'match-probability p-green' : match.forebet_1 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_1}%
                                                </div>
                                                <div className={match.forebet_2 > 50 ? 'match-probability p-green' : match.forebet_2 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_2}%
                                                </div>
                                              </div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_prediction}</div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_average_games}</div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            }
                          })}
                        </>)
                      } else if (selectedSport === '5') {
                        return (<>
                          {Object.entries(grouped_basketball_data).map(([league, { matches, country_image }]) => {
                            if (matches.length == 0) {
                              return (<>
                                <div className="no-matches-container aistats">
                                  <div className="no-picks-row">
                                    <div className="n-picks-inner">
                                      <div className='access-denied-symbol'><img src={denied} /></div>
                                      <h5>No Basketball matches available</h5>
                                      <span id="picks-subtitle">Check back later for further updates</span>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            } else {
                              return (
                                <>
                                  <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                  <table className="wagerly-table">
                                    <thead>
                                      <tr>
                                        <th>Event</th>
                                        <th>Probability</th>
                                        <th className="header-title-center">Prediction</th>
                                        <th>Avg. Points</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {matches.map((match, index) => {
                                        return (
                                          <tr key={index} className="free-table-element">
                                            <td className='match-date'>
                                              <div className="match-time">
                                                {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                <br></br>
                                                {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                              </div>
                                              <div className="match-teams">
                                                <span className="match-teams-team">{match.home_team}</span>
                                                <span className="match-teams-team">{match.away_team}</span>
                                              </div>
                                            </td>
                                            <td className='match-probs'>
                                              <div className='table-inner-content'>
                                                <div className={match.forebet_1 > 50 ? 'match-probability p-green' : match.forebet_1 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_1}%
                                                </div>
                                                <div className={match.forebet_2 > 50 ? 'match-probability p-green' : match.forebet_2 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_2}%
                                                </div>
                                              </div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_prediction}</div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_average_points}</div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            }
                          })}
                        </>)
                      } else if (selectedSport === '6') {
                        return (<>
                          {Object.entries(grouped_baseball_data).map(([league, { matches, country_image }]) => {
                            if (matches.length != 0) {
                              return (<>
                                <div className="no-matches-container aistats">
                                  <div className="no-picks-row">
                                    <div className="n-picks-inner">
                                      <div className='access-denied-symbol'><img src={denied} /></div>
                                      <h5>No Baseball matches available</h5>
                                      <span id="picks-subtitle">Check back later for further updates</span>
                                    </div>
                                  </div>
                                </div>
                              </>)
                            } else {
                              return (
                                <>
                                  <thead className="league-id" id={league.replaceAll("'", "").replaceAll(" ", "")}><span className="league-icon"><img className="league-icon-img" src={"data:image/png;base64," + country_image}></img></span>&nbsp;{league.replaceAll("'", "")}</thead>
                                  <table className="wagerly-table">
                                    <thead>
                                      <tr>
                                        <th>Event</th>
                                        <th>Probability</th>
                                        <th className="header-title-center">Prediction</th>
                                        <th>Avg. Runs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {matches.map((match, index) => {
                                        return (
                                          <tr key={index} className="free-table-element">
                                            <td className='match-date'>
                                              <div className="match-time">
                                                {new Date(Number(match.date_time) * 1000).toLocaleDateString()}
                                                <br></br>
                                                {new Date(Number(match.date_time) * 1000).toLocaleTimeString()}
                                              </div>
                                              <div className="match-teams">
                                                <span className="match-teams-team">{match.home_team}</span>
                                                <span className="match-teams-team">{match.away_team}</span>
                                              </div>
                                            </td>
                                            <td className='match-probs'>
                                              <div className='table-inner-content'>
                                                <div className={match.forebet_1 > 50 ? 'match-probability p-green' : match.forebet_1 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_1}%
                                                </div>
                                                <div className={match.forebet_2 > 50 ? 'match-probability p-green' : match.forebet_2 < 50 ? 'match-probability p-red' : 'match-probability p-neutral'}>
                                                  {match.forebet_2}%
                                                </div>
                                              </div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_prediction}</div>
                                            </td>
                                            <td className='center'>
                                              <div className='table-inner-content'>{match.forebet_average_runs}</div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            }
                          })}
                        </>)
                      }
                    })()
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="no-picks-container aistats">
              <div className="no-picks-row">
                <div className="n-picks-inner">
                  <div className='access-denied-symbol'><img src={userlock} /></div>
                  <h5>Access is denied</h5>
                  {basicUserInfo ? (<>
                    {!isEmailVerified ? (<>
                      <span id="picks-subtitle">Please verify your email address</span>
                    </>) : (<>
                      <span id="picks-subtitle">You do not have access to this page</span>
                    </>)}
                    {!isEmailVerified ? (<>
                      <NavLink to="/" className="access-denied-btn">Return</NavLink>
                    </>) : (<>
                      <NavLink to="/pricing" className="access-denied-btn">Buy plan</NavLink>
                    </>)}
                  </>) : (<>
                    <span id="picks-subtitle">You do not have access to this page</span>
                    <NavLink to="/login" className="access-denied-btn">Login</NavLink>
                  </>)}
                </div>
              </div>
            </div>
          </>
        )}

        <Footer />

      </motion.main>
    </>
  );
};

export default AiStats;