// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.free-access-section {
  background: rgb(5, 26, 8);
  background: linear-gradient(90deg, rgba(5, 26, 8, 1) 0%, rgba(8, 42, 14, 1) 50%, rgba(5, 26, 8, 1) 100%);
}

.free-access-free-picks-unlock-vip-row {
  color: white;
  padding: 25px;
  padding-top: 50px;
  padding-bottom: 50px;

  max-width: 1200px;
  height: 70vh !important
}

.free-access-free-picks-unlock-vip-column {
  text-align: left;
}

.free-access-logo-inner img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/FreeAccess/FreeAccess.style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wGAAwG;AAC1G;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,oBAAoB;;EAEpB,iBAAiB;EACjB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".free-access-section {\n  background: rgb(5, 26, 8);\n  background: linear-gradient(90deg, rgba(5, 26, 8, 1) 0%, rgba(8, 42, 14, 1) 50%, rgba(5, 26, 8, 1) 100%);\n}\n\n.free-access-free-picks-unlock-vip-row {\n  color: white;\n  padding: 25px;\n  padding-top: 50px;\n  padding-bottom: 50px;\n\n  max-width: 1200px;\n  height: 70vh !important\n}\n\n.free-access-free-picks-unlock-vip-column {\n  text-align: left;\n}\n\n.free-access-logo-inner img {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
