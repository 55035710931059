import React from 'react';

import './Loader.style.css';

import loader from '../../img/wagerly-30-frame.gif';

// Loader component
const Loader = () => {
  return (
    <div className="loader-modal">
      <img className="loader-img" src={loader} alt='loader img' />
      {/* <div className="loader-spinner"></div> */}
    </div>
  );
};

export default Loader;