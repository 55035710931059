import { useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { register } from "../../slices/auth.slice";

import './Signup.style.css';

import logo from '../../img/wagerly-logo.png';
import Loader from '../../components/Loader/Loader.component';
import Modal from '../../components/Modal/Modal.component';
import { motion } from 'framer-motion';

const Signup = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_repeat, setPasswordRepeat] = useState("");

  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };

  const status = useAppSelector((state) => state.auth.status);
  const error = useAppSelector((state) => state.auth.error);

  const handleRegister = async () => {

    if (!validateEmail(email)) {
      setErrorModalMessage("This email is invalid.");
      toggleErrorModal();
      return;
    }

    if (password !== password_repeat) {
      setErrorModalMessage("The passwords do not match!");
      toggleErrorModal();
      return;
    }

    if (password.length < 8) {
      setErrorModalMessage("Password must be at least 8 characters!");
      toggleErrorModal();
      return;
    }

    if (email && password) {
      try {
        await dispatch(
          register({
            email,
            password,
            password_repeat
          })
        ).unwrap().then((payload) => {
          navigate("/email-confirmation");
        }).catch((err) => {
          setErrorModalMessage("This user exists, or there is an error.");
          toggleErrorModal();
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      // Show an error message.
    }
  };

  const validateEmail = (email: String) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{ duration: 0.5 }}
      >

        <Modal isOpen={showErrorModal} toggle={toggleErrorModal}>
          <span>{errorModalMessage}</span>
        </Modal>
        <div id="app-signup-container">
          {status === "loading" && (
            <Loader />
          )}
          <div id="signup-form-div">
            <div className="logo-login">
              <img src={logo} alt="wagerly" />
            </div>
            <form onSubmit={(event) => {
              event.preventDefault();
              handleRegister();
            }}>
              <div id="welcome-text">
                <span id="welcome-text-span">Sign up!</span>
              </div>
              <div id="login-text">
                <span id="login-text-span">Get your exclusive free sports picks to your email!</span>
              </div>
              <input
                id="email-input"
                className="signup-input"
                type="email"
                /*pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"*/
                name="email"
                placeholder="iwant@bets.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className="signup-input"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                /*minLength={8}*/
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                id="repeat-password-input"
                className="signup-input"
                type="password"
                name="repeat-password"
                placeholder="Repeat Password"
                value={password_repeat}
                /*minLength={8}*/
                onChange={(e) => setPasswordRepeat(e.target.value)}
                required
              />
              <div id="signup-agreement">
                By signing up, you agree to our <a className="signup-agreement-links" href="#">terms</a> and <a className="signup-agreement-links" href="#">privacy policy</a>.
              </div>
              <button type="submit" className="signup-button">Sign Up</button>
              <NavLink to="/" id="signup-back-button">Back</NavLink>
            </form>
          </div>
        </div>
      </motion.main>
    </>
  );
};

export default Signup;
