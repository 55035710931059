import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from "../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";

import './Pricing.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';

import { Prices } from '../../classes/Prices';

import annualBillingText from '../../img/annual-billing-text.png';

import checkboxIcon from '../../img/checkbox.png';
import checkboxWhiteIcon from '../../img/checkbox-white.png';

import { motion } from 'framer-motion';

const FAQ = ({ faq, index, toggleFAQ }: { faq: any, index: number, toggleFAQ: (index: number) => void }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};

const Pricing = () => {
  const [monthlyPricing, setMonthlyPricing] = useState(true);
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  const [faqs, setFaqs] = useState([
    {
      question: "What is the difference between AI stats and VIP picks?",
      answer:
        "AI stats provide statistical predictions generated by our artificial intelligence algorithms, offering data-driven insights into upcoming matches/events. VIP picks, on the other hand, are handpicked predictions provided by our expert team based on their analysis and expertise.",
      open: true
    },
    {
      question: "How accurate are the AI predictions and VIP picks?",
      answer: "While we strive for accuracy in both AI predictions and VIP picks, it's essential to understand that sports outcomes can be unpredictable. Our AI algorithms and expert team employ advanced analytics and research to provide the best possible predictions, but there's always a degree of uncertainty inherent in sports betting.",
      open: false
    },
    {
      question: "What sports are covered by your predictions?",
      answer: "We cover a wide range of sports, including but not limited to basketball, soccer, hockey, baseball, tennis, and American football. Our goal is to cater to fans of various sports and provide predictions for popular events across different leagues and tournaments.",
      open: false
    },
    {
      question: "How can I access VIP picks?",
      answer: "There are two ways to access VIP picks: by purchasing a paid monthly membership or for Free  by joining a partnered sportsbook casino through our affiliate link and making a minimum deposit. Follow the instructions for more information.",
      open: false
    },
    {
      question: "How do I qualify for the one-month free membership with the partnered sportsbook casino?",
      answer: "To qualify for the one-month free membership, you need to join one of our partnered sportsbook casinos through our affiliate link and make a minimum deposit as specified. Once you've completed these steps, you'll have to send an email with your account information to get your 1 month free.",
      open: false
    },
    {
      question: "Is there a trial period or money-back guarantee for VIP picks?",
      answer: "At this time, we do not offer a trial period or money-back guarantee for VIP picks. However, we stand behind the quality of our predictions and strive to provide valuable insights to our subscribers. If you have any questions or concerns about our services, please don't hesitate to contact us.",
      open: false
    },
    {
      question: "How can I cancel my subscription or membership?",
      answer: "You can cancel your subscription or membership at any time by accessing your account settings on our website. Follow the prompts to cancel, and your subscription will be terminated directly once you cancel even if you have days left in your current subscription. If you encounter any difficulties, please reach out to our customer support team for assistance.",
      open: false
    },
    {
      question: "Do you offer refunds for subscription fees or memberships?",
      answer: "We operate under a strict 'No Refund Policy' for subscription fees and memberships. Once you've subscribed or purchased a membership, refunds will not be provided for any reason. We encourage users to carefully consider their subscription choices before completing the purchase. If you have questions or concerns about our services, please reach out to our customer support team for assistance before subscribing.",
      open: false
    },
    {
      question: "How can I contact customer support if I have further questions?",
      answer: "If you have any additional questions or need assistance, please don't hesitate to contact our customer support team. You can reach us via email at contact@wagerly.com or through the contact form on our website. We're here to help and ensure you have a positive experience with our services.",
      open: false
    },
  ]);

  const toggleFAQ = (index: any) => {
    setFaqs(
      faqs.map((faq: { question: string; answer: string; open: boolean; }, i: any) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  useEffect(() => {
    handleMonthlyPricing(false);
  }, [basicUserInfo]);

  const handleMonthlyPricing = (monthly: boolean) => {
    setMonthlyPricing(monthly);
  }

  return (
    <>

      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />
        <div className="page-container">
          <div className="pricing-container">
            <section className="page-section">
              <div className="hero-row smaller-row">
                <div className="hero-column-one-full mb-5">
                  <span id="pricing-title">Simple, transparent pricing</span><br></br><br></br>
                  <span id="pricing-title-text">Enjoy clear and straightforward pricing with no hidden fees—know exactly what you pay!</span>
                  <br></br><br></br>
                  <div>
                    <div className="timeframe-payment-selector-container">
                      <div className="timeframe-payment-selector">
                        <span className={monthlyPricing ? "timeframe-selector timeframe-selected" : "timeframe-selector"} onClick={() => handleMonthlyPricing(true)}>Monthly billing</span>
                        <span className={monthlyPricing ? "timeframe-selector" : "timeframe-selector timeframe-selected"} onClick={() => handleMonthlyPricing(false)}>Annual billing&nbsp;<span className="save20percent">20%</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="page-section lx plus">
              <div className="hero-row very-long-row-pricing">
                <div className="hero-column price-cards-container-column">
                  <div className="price-cards-row">
                    {Prices.prices.map((item: { title: string; monthly: { price: number; link: string }; yearly: { price: number; link: string }; features: string[]; includes?: string[] }, index: number) => (
                      <>
                        <div className={`price-card-column${index === 1 ? ' price-popular' : ''}`}>
                          <div className={`price-card-title${index === 1 ? ' price-popular' : ''}`}>{item.title}</div>
                          <br></br>
                          {monthlyPricing ? (
                            <>
                              <div className={`price-card-price${index === 1 ? ' price-popular' : ''}`}>{monthlyPricing ? `$${item.monthly.price}` : `$${item.yearly.price}`} <span className={`price-card-per-month-text${index === 1 ? ' price-popular' : ''}`}>/ per month</span></div>
                            </>) : (<>
                              <div className={`price-card-price${index === 1 ? ' price-popular' : ''}`}>{monthlyPricing ? `$${(item.monthly.price * 12).toFixed(2)}` : `$${(item.yearly.price * 12).toFixed(2)}`} <span className={`price-card-per-month-text${index === 1 ? ' price-popular' : ''}`}>/ per year</span></div>
                              <div className={`price-card-price small-pricing${index === 1 ? ' price-popular' : ''}`}>{monthlyPricing ? `$${item.monthly.price}` : `$${item.yearly.price}`} <span className={`price-card-per-month-text small-pricing${index === 1 ? ' price-popular' : ''}`}>/ per month</span></div>
                            </>)
                          }
                          <br></br>
                          {basicUserInfo?.email ? (monthlyPricing ? <Link to={item.monthly.link}><div className={`price-card-button${index === 1 ? ' price-popular-button' : ''}`}>Choose plan</div></Link> : <Link to={item.yearly.link}><div className={`price-card-button${index === 1 ? ' price-popular-button' : ''}`}>Choose plan</div></Link>) : <Link to={"/login"}><div className={`price-card-button${index === 1 ? ' price-popular-button' : ''}`}>Choose plan</div></Link>}
                          <div className={`price-card-features${index === 1 ? ' popular-features-border' : ''}`}>
                            <div className="price-card-features-title">FEATURES</div>
                            {item.features.map((feature) => (
                              <>
                                <div className={`price-card-feature${index === 1 ? ' price-card-feature-popular' : ''}`}>
                                  <span className="price-card-feature-checkbox-container">
                                    <img width="20px" src={index === 1 ? checkboxWhiteIcon : checkboxIcon} alt="checkbox" />
                                  </span>
                                  <span className="feature-text">{feature}</span>
                                </div>
                              </>

                            ))}

                            {item.includes ? (
                              <>
                                <br></br>
                                <div className="price-card-analyses-included-text">Analyses included:</div>
                                {item.includes!.map((sport) => (
                                  <>
                                    <div className={`price-card-feature${index === 1 ? ' price-card-feature-popular' : ''}`}>
                                      <span className="price-card-feature-checkbox-container">
                                        <img width="20px" src={index === 1 ? checkboxWhiteIcon : checkboxIcon} alt="checkbox" />
                                      </span>
                                      <span className="feature-text">{sport}</span>
                                    </div>
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )
                            }
                          </div>
                        </div>
                      </>))}
                  </div>
                </div>
              </div>
            </section>
            <section className="page-section lx">
              <div className="hero-row small-row-pricing-page">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps" className='faq-title'>Frequently asked questions</span>
                  <span id="affiliate-text" className='faq-text'>Everything you need to know about the affiliate program.</span>
                  <div className="faqs">
                    {faqs.map((faq: any, index: React.Key | null | undefined) => (
                      <FAQ faq={faq} index={index as number} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />

      </motion.main>
    </>
  );
};

export default Pricing;
