import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './Affiliate.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';
import Modal from '../../components/Modal/Modal.component';
import becomeAffiliate from '../../img/become-affiliate.png';
import numberOneIcon from '../../img/numbers/01.png';
import numberTwoIcon from '../../img/numbers/02.png';
import numberThreeIcon from '../../img/numbers/03.png';
import logo from '../../img/wagerly-logo.png';
import loadingGIF from '../../img/loading.gif';

import { useAppDispatch } from "../../hooks/redux-hooks";
import { sendAffiliateEmail } from "../../slices/sub.slice";

import { motion } from 'framer-motion';

const FAQ = ({ faq, index, toggleFAQ }: { faq: any, index: number, toggleFAQ: (index: number) => void }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};

const Affiliate = () => {
  const dispatch = useAppDispatch();

  const [isAffiliateModalOpen, setIsAffiliateModalOpen] = useState(false);

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");

  const [affiliateMessage, setAffiliateMessage] = useState("");
  const [isAffiliateFeebackModalOpen, setIsAffiliateFeebackModalOpen] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const [isAffiliateValidCheck, setIsAffiliateValidChecked] = useState(false);

  const [faqs, setFaqs] = useState([
    {
      question: "What is the difference between AI stats and VIP picks?",
      answer:
        "AI stats provide statistical predictions generated by our artificial intelligence algorithms, offering data-driven insights into upcoming matches/events. VIP picks, on the other hand, are handpicked predictions provided by our expert team based on their analysis and expertise.",
      open: true
    },
    {
      question: "How accurate are the AI predictions and VIP picks?",
      answer: "While we strive for accuracy in both AI predictions and VIP picks, it's essential to understand that sports outcomes can be unpredictable. Our AI algorithms and expert team employ advanced analytics and research to provide the best possible predictions, but there's always a degree of uncertainty inherent in sports betting.",
      open: false
    },
    {
      question: "What sports are covered by your predictions?",
      answer: "We cover a wide range of sports, including but not limited to basketball, soccer, hockey, baseball, tennis, and American football. Our goal is to cater to fans of various sports and provide predictions for popular events across different leagues and tournaments.",
      open: false
    },
    {
      question: "How can I access VIP picks?",
      answer: "There are two ways to access VIP picks: by purchasing a paid monthly membership or for Free  by joining a partnered sportsbook casino through our affiliate link and making a minimum deposit. Follow the instructions for more information.",
      open: false
    },
    {
      question: "How do I qualify for the one-month free membership with the partnered sportsbook casino?",
      answer: "To qualify for the one-month free membership, you need to join one of our partnered sportsbook casinos through our affiliate link and make a minimum deposit as specified. Once you've completed these steps, you'll have to send an email with your account information to get your 1 month free.",
      open: false
    },
    {
      question: "Is there a trial period or money-back guarantee for VIP picks?",
      answer: "At this time, we do not offer a trial period or money-back guarantee for VIP picks. However, we stand behind the quality of our predictions and strive to provide valuable insights to our subscribers. If you have any questions or concerns about our services, please don't hesitate to contact us.",
      open: false
    },
    {
      question: "How can I cancel my subscription or membership?",
      answer: "You can cancel your subscription or membership at any time by accessing your account settings on our website. Follow the prompts to cancel, and your subscription will be terminated directly once you cancel even if you have days left in your current subscription. If you encounter any difficulties, please reach out to our customer support team for assistance.",
      open: false
    },
    {
      question: "Do you offer refunds for subscription fees or memberships?",
      answer: "We operate under a strict 'No Refund Policy' for subscription fees and memberships. Once you've subscribed or purchased a membership, refunds will not be provided for any reason. We encourage users to carefully consider their subscription choices before completing the purchase. If you have questions or concerns about our services, please reach out to our customer support team for assistance before subscribing.",
      open: false
    },
    {
      question: "How can I contact customer support if I have further questions?",
      answer: "If you have any additional questions or need assistance, please don't hesitate to contact our customer support team. You can reach us via email at contact@wagerly.com or through the contact form on our website. We're here to help and ensure you have a positive experience with our services.",
      open: false
    },
  ]);

  const toggleFAQ = (index: any) => {
    setFaqs(
      faqs.map((faq: { question: string; answer: string; open: boolean; }, i: any) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  const toggleAffiliateModal = () => {
    setIsAffiliateModalOpen(!isAffiliateModalOpen);

    if (!isAffiliateModalOpen) {
      setIsAffiliateValidChecked(false);
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  };

  const handleAffiliateSubscription = async () => {
    if (!isSending) {

      setIsSending(true);

      try {
        let res = await dispatch(sendAffiliateEmail({
          name: name,
          country: country,
          state: state,
          address: address,
          username: username,
          message: message,
        })).unwrap();

        if (res.status == 201) {
          setAffiliateMessage("Affiliate registration succesfully sent!")
          toggleAffiliateFeebackModalOpen();
          toggleAffiliateModal();

          resetValues()
        } else {
          setAffiliateMessage("An error occured. Please try again later.");
        }
      } catch (e) {
        console.error(e);
        setAffiliateMessage("An error occured. Please try again later.");
      }

      setIsSending(false);
    }
  }

  const handleButtonState = (): boolean => {
    let currentState = (
      !isAffiliateValidCheck ||
      !(name !== "") ||
      !(country !== "") ||
      !(state !== "") ||
      !(address !== "") ||
      !(username !== "") ||
      !(message !== "") && !isSending
    )

    return currentState;
  }

  const toggleAffiliateFeebackModalOpen = () => {
    setIsAffiliateFeebackModalOpen(!isAffiliateFeebackModalOpen);

    if (!isAffiliateFeebackModalOpen) {
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
  };

  const resetValues = () => {
    setName("");
    setCountry("");
    setState("");
    setAddress("");
    setUsername("");
    setMessage("");

    setIsAffiliateValidChecked(false);


  }

  return (
    <>

      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />
        <div className="page-container">
          <div className="affiliate-container pb-5">
            <section className="page-section join-wagerly-section pt-5 pb-5 mb-5">
              <div className="row hero-row">
                <div className="hero-column-half affiliate-half col order-1 order-md-0">
                  <span id="affiliate-title">Join the Wagerly Affiliate Program</span><br></br><br></br>
                  <span id="affiliate-title-text">Become a valued partner in our Wagerly Affiliate Program and unlock the potential for significant earnings. By promoting our exclusive AISTATS and VIP picks, you can enjoy competitive commissions while providing your audience with top-notch insights. Join us today and take the first step towards a rewarding partnership that benefits both you and your followers!</span><br></br><br></br><br></br>
                  <button onClick={toggleAffiliateModal} className="get-started-button">Become an affiliate</button>
                </div>
                <div className="hero-column-half col-lg col-12 col-md">
                  <img id="affiliate-image" src={becomeAffiliate} alt='affiliate img' />
                </div>
              </div>
            </section>
            <section className="page-section fts mb-5">
              <div className="hero-row medium-row">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps">How does the Wagerly affiliate program work?</span><br></br><br></br>
                  <div className="three-rows-affiliate d-flex flex-wrap text-left">
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberOneIcon} alt='number one' /></span><br></br>
                      <span className="affiliate-text-title">Sign up to become an affiliate</span><br></br>
                      <p className="affiliate-text">Join our community of affiliates and start your journey towards earning commissions by promoting our exclusive offerings</p>
                      {/*<NavLink to="/pricing" className="get-started-button">Submit form</NavLink>*/}
                    </div>
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberTwoIcon} alt='number two' /></span><br></br>
                      <span className="affiliate-text-title">Create and share your affiliate link</span><br></br>
                      <p className="affiliate-text">Generate your unique affiliate link and share it across your platforms to attract potential customers effortlessly.</p>
                    </div>
                    <div className="col-lg-4 col-12 mb-5 col-md-4">
                      <span className="affiliate-number"><img src={numberThreeIcon} alt='number three' /></span><br></br>
                      <span className="affiliate-text-title">Earn up to 50% commissions</span><br></br>
                      <p className="affiliate-text">Maximize your earnings with competitive commission rates, rewarding you for every successful referral you make.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="page-section get-started-light-green affiliate-get-started mt-5 mb-5">
              <div className="hero-row very-small-row">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps">Promote Wagerly. Get paid. Simple right?</span><br></br>
                  <span id="affiliate-text">Join our affiliate program and start earning commissions by sharing valuable insights with your network!</span><br></br><br></br>
                  <br></br>
                  <NavLink to="/pricing" className="get-started-button">Get Started</NavLink>
                </div>
              </div>
            </section>
            <section className="page-section mt-5 mb-5">
              <div className="hero-row">
                <div className="hero-column-one-full">
                  <span id="affiliate-title-steps" className='faq-title'>Frequently asked questions</span>
                  <span id="affiliate-text" className='faq-text'>Everything you need to know about the affiliate program.</span>
                  <div className="faqs">
                    {faqs.map((faq: any, index: React.Key | null | undefined) => (
                      <FAQ faq={faq} index={index as number} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />

      </motion.main>
      <Modal className='affiliate-modal' isOpen={isAffiliateModalOpen} toggle={toggleAffiliateModal}>
        <div className={`affiliate-loading ${isSending ? 'affiliate-loading-open' : ''}`}>
          <div className="modal-title">
            <img src={loadingGIF} />
          </div>
        </div>
        <div className="affiliate-modal-top">
          <img src={logo} />
          <div className="modal-title">Become an affiliate</div>
          <p className="modal-message">Earn up to 50% commissions</p>
        </div>
        <div className="modal-content">
          <form className="affiliate-form">
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>Full name</label>
              <input id='name' type="text" placeholder="Full name" onChange={(e) => setName(e.currentTarget.value)} value={name} />
            </div>
            <div className="form-group col-md-6 free-access-f-1">
              <label className='nopadding-mobile'>Country</label>
              <input id='country' type="text" placeholder="Country" onChange={(e) => setCountry(e.currentTarget.value)} value={country} />
            </div>
            <div className="form-group col-md-6 free-access-f-1">
              <label className='nopadding-mobile'>State / Province</label>
              <input id='state' type="text" placeholder="State / Province" onChange={(e) => setState(e.currentTarget.value)} value={state} />
            </div>
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>Address</label>
              <input id='address' type="text" placeholder="Address" onChange={(e) => setAddress(e.currentTarget.value)} value={address} />
            </div>
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>Username</label>
              <input id='username' type="text" placeholder="Username " onChange={(e) => setUsername(e.currentTarget.value)} value={username} />
            </div>
            <div className="form-group col-md-12">
              <label className='nopadding-mobile'>How are you planning to promote our services?</label>
              <textarea id='message' placeholder="I am planning to..." onChange={(e) => setMessage(e.currentTarget.value)} value={message}></textarea>
            </div>
          </form>
          <div className="modal-checkbox">
            <input
              type="checkbox"
              id="confirm-cancel"
              checked={isAffiliateValidCheck}
              onChange={(e) => setIsAffiliateValidChecked(e.target.checked)}
            />
            <label htmlFor="confirm-cancel">
              I confirm that I am of legal age to participate in betting in my country.
            </label>
          </div>
        </div>
        <div className="modal-buttons">
          <button
            className="modal-button modal-button-confirm"
            onClick={handleAffiliateSubscription}
            disabled={handleButtonState()}
          >
            Become an affiliate
          </button>
        </div>
      </Modal>
      <Modal isOpen={isAffiliateFeebackModalOpen} toggle={toggleAffiliateFeebackModalOpen} className='affiliate-success'>
        <div className="modal-header">
          <div className="modal-title">{affiliateMessage}</div>
        </div>
        <div className="modal-buttons">
          <button className="modal-button modal-button-cancel" onClick={toggleAffiliateFeebackModalOpen}>Dismiss</button>
        </div>
      </Modal>
    </>
  );
};

export default Affiliate;
