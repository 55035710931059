import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import MenuBar from '../../components/MenuBar/MenuBar.component';

import './Home.style.css';

import homepageAnim from '../../img/animations/5.gif';
import aiStatsAnim from '../../img/animations/1.gif';
import vipPicksAnim from '../../img/animations/2.gif';
import casinosAnim from '../../img/animations/4.gif';
import checkmarkImage from '../../img/checkmarck-poweredby.png';

import aiIcon from '../../img/ai-icon.png';
import vipPicksIcon from '../../img/vip-picks-icon.png';
import casinosIcon from '../../img/casino-promos-icon.png';

import freePicksUnlockVIP from '../../img/free-picks-unlock-vip.png';

import joinWagerly from '../../img/join-wagerly.png';
import becomeAffiliate from '../../img/become-affiliate.png';

import checkbox from '../../img/checkbox.png';

import Footer from '../../components/Footer/Footer.component';
import { motion } from 'framer-motion';

const FAQ = ({ faq, index, toggleFAQ }: { faq: any, index: number, toggleFAQ: (index: number) => void }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};

const Home = () => {

  const [faqs, setFaqs] = useState([
    {
      question: "What is the difference between AI stats and VIP picks?",
      answer:
        "AI stats provide statistical predictions generated by our artificial intelligence algorithms, offering data-driven insights into upcoming matches/events. VIP picks, on the other hand, are handpicked predictions provided by our expert team based on their analysis and expertise.",
      open: true
    },
    {
      question: "How accurate are the AI predictions and VIP picks?",
      answer: "While we strive for accuracy in both AI predictions and VIP picks, it's essential to understand that sports outcomes can be unpredictable. Our AI algorithms and expert team employ advanced analytics and research to provide the best possible predictions, but there's always a degree of uncertainty inherent in sports betting.",
      open: false
    },
    {
      question: "What sports are covered by your predictions?",
      answer: "We cover a wide range of sports, including but not limited to basketball, soccer, hockey, baseball, tennis, and American football. Our goal is to cater to fans of various sports and provide predictions for popular events across different leagues and tournaments.",
      open: false
    },
    {
      question: "How can I access VIP picks?",
      answer: "There are two ways to access VIP picks: by purchasing a paid monthly membership or for Free  by joining a partnered sportsbook casino through our affiliate link and making a minimum deposit. Follow the instructions for more information.",
      open: false
    },
    {
      question: "How do I qualify for the one-month free membership with the partnered sportsbook casino?",
      answer: "To qualify for the one-month free membership, you need to join one of our partnered sportsbook casinos through our affiliate link and make a minimum deposit as specified. Once you've completed these steps, you'll have to send an email with your account information to get your 1 month free.",
      open: false
    },
    {
      question: "Is there a trial period or money-back guarantee for VIP picks?",
      answer: "At this time, we do not offer a trial period or money-back guarantee for VIP picks. However, we stand behind the quality of our predictions and strive to provide valuable insights to our subscribers. If you have any questions or concerns about our services, please don't hesitate to contact us.",
      open: false
    },
    {
      question: "How can I cancel my subscription or membership?",
      answer: "You can cancel your subscription or membership at any time by accessing your account settings on our website. Follow the prompts to cancel, and your subscription will be terminated directly once you cancel even if you have days left in your current subscription. If you encounter any difficulties, please reach out to our customer support team for assistance.",
      open: false
    },
    {
      question: "Do you offer refunds for subscription fees or memberships?",
      answer: "We operate under a strict 'No Refund Policy' for subscription fees and memberships. Once you've subscribed or purchased a membership, refunds will not be provided for any reason. We encourage users to carefully consider their subscription choices before completing the purchase. If you have questions or concerns about our services, please reach out to our customer support team for assistance before subscribing.",
      open: false
    },
    {
      question: "How can I contact customer support if I have further questions?",
      answer: "If you have any additional questions or need assistance, please don't hesitate to contact our customer support team. You can reach us via email at contact@wagerly.com or through the contact form on our website. We're here to help and ensure you have a positive experience with our services.",
      open: false
    },
  ]);

  const toggleFAQ = (index: any) => {
    setFaqs(
      faqs.map((faq: { question: string; answer: string; open: boolean; }, i: any) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };


  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <MenuBar />

        <div className="page-container">
          {/* Betting Section */}
          <section className="page-section betting-section">
            <div className="row hero-row">
              <div className="col-lg-6 col-md-6 col-12 hero-align-left order-1 order-md-0">
                <span id="homepage-powered-by"><img id="checkmark-image" src={checkmarkImage}></img> Powered By <span id="powered-by-yulpronos">Yulpronos</span></span><br></br><br></br>
                <span id="homepage-title"><span className='homepage-title-span text-trophe-greenback'>🏆#1  </span>&nbsp;Sports Betting Reference</span><br></br><br></br>
                <span id="homepage-title-text">Your All-in-One Solution to Dominate the Betting Game!</span><br></br><br></br><br></br>
                <NavLink to="/pricing" className="get-started-button">Get Started</NavLink>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half">
                <img id="numbers-image" src={homepageAnim}></img>
              </div>
            </div>
          </section>

          <section className="page-section explanation-section pb-5">
            <div className="hero-row smaller-row">
              <div className="hero-column-one-full middle-features">
                <span id="features-header">Features</span>
                <span className="section-title-center">Stay Ahead of the Game with Exclusive Data and Analytics!</span>
                <div className="features-text">
                  <p>Our subscription services offers a winning combination of expertly curated picks and cutting-edge AI-driven predictions on all Sports.</p>
                  <p>Elevate your game, break free from losses, and join a community of successful bettors. Subscribe now to unlock the tools you need for unparalleled success in sports betting - it's time to bet smarter and win bigger!</p>
                </div>
              </div>
            </div>
          </section>

          <section className="page-section explanation-section">
            <div className="row hero-row medium-row">
              <div className="col-lg-6 col-md-6 col-12 hero-column-half nopadding-mobile">
                <div className="showcase-section-icon"><img width="100%" src={aiIcon}></img></div>
                <div className="showcase-section-title">AI STATS</div>
                <div className="showcase-section-text">Powered by AI and mathematical algorithms, this service provides daily predictions for various sports games. Unlike traditional methods that rely on intuition or gut feelings, our AI Stat service analyzes vast amounts of historical and current data to generate accurate forecasts.</div>
                <div className="showcase-section-checkboxes">
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Data Driven Predictions
                  </div>
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Algorithmic Precision
                  </div>
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Multi-Sports Coverage - Daily Updates
                  </div>
                </div>
                <div className="showcase-section-get-started-link">
                  <NavLink to="/pricing" className="get-started-button">Get Started</NavLink>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half nopadding-mobile">
                <img id="ai-stats-showcase" src={aiStatsAnim}></img>
              </div>
            </div>
          </section>

          <section className="page-section explanation-section">
            <div className="row hero-row medium-row">
              <div className="col-lg-6 col-md-6 col-12 hero-column-half order-1 order-md-0 nopadding-mobile">
                <img id="ai-stats-showcase" src={vipPicksAnim}></img>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half nopadding-mobile">
                <div className="showcase-section-icon"><img width="100%" src={vipPicksIcon}></img></div>
                <div className="showcase-section-title">VIP PICKS</div>
                <div className="showcase-section-text">Meticulously curated by our team of analysts, these picks are the result of expert insights, in-depth research, and a thorough understanding of the current sports landscape.<br></br><br></br>Unlike relying on chance of intuition, our analysts leverage their expertise to select the most promising bets across various sports.</div>
                <div className="showcase-section-checkboxes">
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Expert Analysis
                  </div>
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Comprehensive Research and Analysis
                  </div>
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Diverse Sports Coverage / Transparency
                  </div>
                </div>
                <div className="showcase-section-get-started-link">
                  <NavLink to="/pricing" className="get-started-button">Get Started</NavLink>
                </div>
              </div>
            </div>
          </section>

          <section className="page-section explanation-section">
            <div className="row hero-row medium-row">
              <div className="col-lg-6 col-md-6 col-12 hero-column-half nopadding-mobile">
                <div className="showcase-section-icon"><img width="100%" src={casinosIcon}></img></div>
                <div className="showcase-section-title">BEST CASINOS</div>
                <div className="showcase-section-text">Explore a world of exclusive offers. We've handpicked the best sportsbooks in the market, each offering enticing welcome promotions. From bonus funds to free bets, elevate your sports betting experience with these exclusive deals.</div>
                <div className="showcase-section-checkboxes">
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Access to Exclusive Offers
                  </div>
                  <div className="showcase-section-checkbox">
                    <span className="show-section-checkbox-icon"><img width="20px" src={checkbox}></img></span> Handpicked the best Sportsbooks
                  </div>
                </div>
                <div className="showcase-section-get-started-link">
                  <NavLink to="/casinos" className="get-started-button">Claim deals</NavLink>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half nopadding-mobile">
                <img id="ai-stats-showcase" src={casinosAnim}></img>
              </div>
            </div>
          </section>

          <section className="page-section explanation-section">
            <div className="row hero-row free-picks-unlock-vip-row">
              <div className="col-lg-6 col-md-6 col-12 hero-column-half free-picks-unlock-vip-column nopadding-mobile">
                <div className="free-picks-unlock-vip-title">Sign Up to our Partnered casino and get 1 month <span className="free-picks-unlock-vip-orange">FREE</span> of <span className="free-picks-unlock-vip-orange">VIP</span> picks</div>
                <br></br>
                <div className="free-picks-unlock-vip-link">
                  <NavLink to="/freeaccess" className="free-picks-unlock-vip-button">Sign Up</NavLink>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half free-picks-unlock-vip-column nopadding-mobile">
                <img id="free-picks-unlock-vip-image" src={freePicksUnlockVIP}></img>
                <NavLink to="/pricing" className="free-picks-unlock-vip-button abs-photo">Unlock VIP access</NavLink>
              </div>
            </div>
          </section>

          <section className="page-section-cta home-cta mb-5">
            <div className="row hero-row full-page">
              <div className="col-lg-6 col-md-6 col-12 hero-column-half">
                <img className="cta-img-showcase" src={joinWagerly}></img>
                <br></br>
                <div className="home-page-cta-title">
                  Earn Money with Wagerly
                </div>
                <div className="home-page-cta-text">
                  Introducing Our Lucrative Affiliate Opportunity
                </div>
                <NavLink to="/signup" className="get-started-button">Join Wagerly</NavLink>
                <br></br>
                <br></br>
              </div>
              <div className="col-lg-6 col-md-6 col-12 hero-column-half">
                <img className="cta-img-showcase" src={becomeAffiliate}></img>
                <br></br>
                <div className="home-page-cta-title">
                  Promote Wagerly. Get paid. Simple right?
                </div>
                <div className="home-page-cta-text">
                  Find out how you could turn your sports betting passion into money!
                </div>
                <NavLink to="/affiliate" className="get-started-button">Become an affiliate</NavLink>
                <br></br>
                <br></br>
              </div>
            </div>
          </section>

          <section className="page-section">
            <div className="hero-row h-auto">
              <div className="hero-column-one-full">
                <span id="affiliate-title-steps" className='faq-title'>Frequently asked questions</span>
                <span id="affiliate-text" className='faq-text'>Everything you need to know about Wagerly.</span>
                <div className="faqs">
                  {faqs.map((faq: any, index: React.Key | null | undefined) => (
                    <FAQ faq={faq} index={index as number} key={index} toggleFAQ={toggleFAQ} />
                  ))}
                </div>
              </div>
            </div>
          </section>

          <Footer />

        </div>

      </motion.main>
    </>
  );
};

export default Home;