import React from 'react';

import './SocialMedia.style.css';

import facebookIcon from '../../img/facebook-icon.svg';
import instagramIcon from '../../img/instagram-icon.svg';
import xIcon from '../../img/x-icon.svg';

const socialMedia = () => {

  return (
    <div className="social-media">
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <img className="social-media-image" src={facebookIcon} alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/wagerly.ca?igsh=MzhsczRxeTh6eWhi" target="_blank" rel="noopener noreferrer">
        <img className="social-media-image" src={instagramIcon} alt="Instagram" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <img className="social-media-image" src={xIcon} alt="Twitter" />
      </a>
    </div>
  )
}

export default socialMedia;