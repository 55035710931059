import React, { useState } from 'react';

import leftChevron from '../../img/aistats/left-chevron.png';

import './CollapsibleCountry.style.css';

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  enabled: boolean;
}

const CollapsibleCountry: React.FC<CollapsibleProps> = ({ title, children, enabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapsible = () => {
    if (!enabled) {
      setIsOpen(!isOpen)
    }
  }
  return (
    <div className={`collapsible${enabled ? ' disabled' : ''}`}>
      <span onClick={toggleCollapsible} className="collapsible__toggle">
        {title}
        <span onClick={toggleCollapsible} className={`collapsible__arrow ${isOpen ? 'open' : 'closed'}`}>
          <img src={leftChevron} alt="left-chevron" width="10px" />
        </span>
      </span>
      {isOpen && <div className="collapsible__content">{children}</div>}
    </div>
  );
};

export default CollapsibleCountry;