/*
* Author: Iyad Al-Kassab
* Date: 29/11/2023
* Description: MenuBar component
*/

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getUser, logout } from "../../slices/auth.slice";
import { useNavigate } from "react-router-dom";

import SocialMedia from '../SocialMedia/SocialMedia.component';

import './MenuBar.style.css';

import logo from '../../img/wagerly-logo.png';
import LeftBar from '../LeftBar/LeftBar.component';
import ProfileButton from '../ProfileButton/profileButton.component';

const MenuBar = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate("/login");
    } catch (e) {
      console.error(e);
    }
  };

  const handleSettings = async () => {
    try {
      navigate("/settings/details");
    } catch (e) {
      console.error(e);
    }
  };

  const handleBilling = async () => {
    try {
      navigate("/settings/billing");
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubscription = async () => {
    try {
      navigate("/settings/subscription");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="topbar">

        <div className="logo">
          <NavLink to="/">
            <img src={logo} alt="wagerly" />
          </NavLink>
        </div>

        <div className={`menu-left ${isOpen ? 'menu-open' : 'menu-closed'}`}>
          <NavLink to="/" className="menu-button">Home</NavLink>
          <NavLink to="/aistats" className="menu-button">AI Stats</NavLink>
          <NavLink to="/ourpicks" className="menu-button">Our Picks</NavLink>
          <NavLink to="/freeaccess" className="menu-button">Free Access</NavLink>
          <NavLink to="/casinos" className="menu-button">Best Casinos</NavLink>
          <NavLink to="/affiliate" className="menu-button">Affiliate</NavLink>
          <NavLink to="/pricing" className="menu-button">Pricing</NavLink>
          {basicUserInfo?.email ? (
            <React.Fragment>
              <NavLink to="/settings/details" className="menu-button menu-item-hidden">Settings</NavLink>
              <button type="submit" onClick={handleLogout} className="menu-item-login menu-button menu-item-login-btn">Log out</button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink to="/login" className="menu-item-login menu-button">Login</NavLink>
              <NavLink to="/signup" className="menu-item-login menu-button">Sign Up</NavLink>
            </React.Fragment>

          )}
        </div>
        <div className="menu-right">
          {basicUserInfo?.email ? (
            <div className="menu-links">
              <ProfileButton email={basicUserInfo.email} onSettingsClick={handleSettings} onBillingClick={handleBilling} onSubscriptionClick={handleSubscription} onLogoutClick={handleLogout} />
            </div>
          ) : (
            <div className="menu-links">
              <NavLink to="/login" id="login-button">Login</NavLink>
              <NavLink to="/signup" id="signup-button">Sign Up</NavLink>
            </div>
          )}
        </div>
        <div className='menu-hamburger' onClick={() => setIsOpen(!isOpen)}>
          <div className='menu-h-line'></div>
          <div className='menu-h-line'></div>
          <div className='menu-h-line'></div>
        </div>
      </div>
    </>
  )
}

export default MenuBar;