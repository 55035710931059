// export default async function toBase64ImageUrl(imgUrl: string): Promise<string> {
//   const fetchImageUrl = await fetch(imgUrl);
//   const responseArrBuffer = await fetchImageUrl.arrayBuffer();
//   const toBase64 = `data:${ fetchImageUrl.headers.get('Content-Type') || 'image/png' };base64,${Buffer.from(responseArrBuffer).toString('base64')}`;

//   return toBase64
// }

export default async function convertFileListToBase64(fileList: FileList): Promise<string> {
  return new Promise((resolve, reject) => {
    if (fileList.length === 0) {
      return reject(new Error('No file selected.'));
    }

    const file = fileList[0]; // Get the first file

    if (!file.type.startsWith('image/')) {
      return reject(new Error('Selected file is not an image.'));
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string); // Base64 string
      } else {
        reject(new Error('Failed to read file.'));
      }
    };

    reader.onerror = () => reject(new Error('Error reading file.'));

    reader.readAsDataURL(file); // Read file as base64 string
  });
};