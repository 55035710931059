// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

    Loader MODAL

*/

.loader-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 0%);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 1;
  transition: 0.2s ease-in;
}

.loader-spinner {

  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #63d06d) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn)
  }
}

.loader-img {
  width: 15%;
}

@media(max-width: 991px) {
  .loader-img {
    width: 60%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.style.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;EACvC,kCAA0B;UAA1B,0BAA0B;EAC1B,UAAU;EACV,wBAAwB;AAC1B;;AAEA;;EAEE,WAAW;EACX,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,0DAA0D;EAC1D;;iGAE+F;EAC/F,sCAAsC;EACtC,yBAAyB;EACzB,mCAAmC;AACrC;;AAEA;EACE;IACE;EACF;AACF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["/*\r\n\r\n    Loader MODAL\r\n\r\n*/\r\n\r\n.loader-modal {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-color: rgb(255 255 255 / 0%);\r\n  backdrop-filter: blur(4px);\r\n  z-index: 1;\r\n  transition: 0.2s ease-in;\r\n}\r\n\r\n.loader-spinner {\r\n\r\n  width: 50px;\r\n  --b: 8px;\r\n  aspect-ratio: 1;\r\n  border-radius: 50%;\r\n  padding: 1px;\r\n  background: conic-gradient(#0000 10%, #63d06d) content-box;\r\n  -webkit-mask:\r\n    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),\r\n    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));\r\n  -webkit-mask-composite: destination-in;\r\n  mask-composite: intersect;\r\n  animation: l4 1s infinite steps(10);\r\n}\r\n\r\n@keyframes l4 {\r\n  to {\r\n    transform: rotate(1turn)\r\n  }\r\n}\r\n\r\n.loader-img {\r\n  width: 15%;\r\n}\r\n\r\n@media(max-width: 991px) {\r\n  .loader-img {\r\n    width: 60%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
