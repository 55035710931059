import { useEffect } from 'react';
import MatchPickForm from "../../components/MatchPickForm/MatchPickForm.component";
import { motion } from 'framer-motion';

import './Admin.style.css';

const AdminPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <motion.main
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
      >

        <div className='page-container'>
          <div className="admin-page-ap">
            <h1 className="admin-page-title-ap">Admin Page</h1>
            <MatchPickForm />
          </div>
        </div>
      </motion.main>
    </>
  );
};

export default AdminPage;
