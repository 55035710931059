import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

import './Verify.style.css';

import MenuBar from '../../components/MenuBar/MenuBar.component';
import Footer from '../../components/Footer/Footer.component';

import userlock from '../../img/user-lock.svg';

import { motion } from 'framer-motion';

import { verifyAccount } from "../../slices/auth.slice";

const Verify = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState("");
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {

    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (basicUserInfo?.email && token) {
        try {
          const res = await dispatch(
            verifyAccount({
              token: token,
            })
          ).unwrap();

          setVerificationStatus(res?.message || "An unexpected error occured. Please try again later");

        } catch (e) {
          console.error(e);
          setVerificationStatus("An unexpected error occured. Please try again later");
        }
      }

      setIsLoading(false); // Stop loading when check is complete
    }

    verifyEmail();

  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <motion.main
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -100 }}
      transition={{ duration: 0.5 }}
    >
      <MenuBar />
      <div className="page-container">
        <div className="picks-container">
          <div className="picks-row">
            <div className="col-12">
              <div className="no-picks-container">
                <div className="no-picks-row">
                  <div className="n-picks-inner">
                    <div className='access-denied-symbol'><img src={userlock} /></div>
                    <h5>Email Verification</h5>
                    <span id="picks-subtitle">{verificationStatus}</span>
                    <NavLink to="/" className="access-denied-btn">Return Home</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </motion.main>
  );
};

export default Verify;